import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import Error from '../../shared/Error';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { PreviousButton } from './Components/PreviousButton';

const Pin = ({ supabase, phoneNumber, onComplete, onBack, testMode, env }) => {
  const [error, setError] = useState(null);
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'active' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  const onContinuePin = async (values, { resetForm }) => {
    if (testMode || env === 'sandbox') {
      // Don't verify pin and just move to the next screen.
      onComplete();
      return;
    }

    setIsLoading(true);

    const pin = Object.values(values).join('');
    const { error: optError } = await supabase.auth.verifyOtp({
      phone: phoneNumber,
      token: pin,
      type: 'sms',
    });

    if (optError) {
      setError('Incorrect pin. Please double-check the SMS pin we sent.');
      resetForm();
      setIsLoading(false);
    } else {
      onComplete();
    }
  };

  const schema = object().shape({
    pin0: string().required('Pin is required'),
    pin1: string().required('Pin is required'),
    pin2: string().required('Pin is required'),
    pin3: string().required('Pin is required'),
    pin4: string().required('Pin is required'),
    pin5: string().required('Pin is required'),
  });

  const handleInputChange = (e, index, setFieldValue, values) => {
    const value = e.target.value;

    // If the user is trying to delete a character and the current input is empty
    if (e.nativeEvent.inputType === 'deleteContentBackward' && value.length === 0) {
      e.preventDefault(); // Prevent the default behavior (focus shift)
      setFieldValue(`pin${index}`, ''); // Clear the current input's value
      return;
    }

    if (value.length === 1) {
      setFieldValue(`pin${index}`, value);
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length > 1) {
      const pins = value.split('');
      pins.forEach((pin, idx) => {
        if (idx < 6) {
          setFieldValue(`pin${idx}`, pin);
        }
      });
    }
  };

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      <StepsCircles steps={STEPS} />

      <br />
      <br />
      <div className="max-w-xl mx-auto">
        <div className="flex flex-col w-full">
          <h1 className="text-black text-4xl text-left font-bold mb-4 self-center">
            Check your messages
          </h1>
          <p className="mt-2 text-left text-sm text-gray-600 self-center">
            Enter the 6-digit verification code sent to your phone number
          </p>
          <Formik
            initialValues={{
              pin0: '',
              pin1: '',
              pin2: '',
              pin3: '',
              pin4: '',
              pin5: '',
            }}
            validationSchema={schema}
            onSubmit={onContinuePin}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="mt-8 space-y-6">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <div className="flex space-x-2">
                    {[...Array(6)].map((_, index) => (
                      <Field
                        key={index}
                        name={`pin${index}`}
                        type="text"
                        value={values[`pin${index}`]}
                        className="border-b-2 border-gray-300 w-8 text-center text-bg-[#14532d] font-medium text-xl p-2 shadow-sm transition-transform transform hover:scale-105 focus:outline-none focus:scale-105 focus:border-indigo-500 green-input" // Added green-input class
                        maxLength="6"
                        onChange={(e) => handleInputChange(e, index, setFieldValue, values)}
                        innerRef={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <div className="text-red-500">
                    <ErrorMessage name="pin0" />
                  </div>
                </div>
                {error && <Error message={error} />}
                <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
                  <div className="w-full mb-2">
                    <ContinueButton isLoading={isLoading} />
                    <PreviousButton isLoading={isLoading} onClick={onBack} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Pin;
