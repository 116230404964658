import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string, number, date, boolean } from 'yup';
import parse from 'date-fns/parse';
import isDate from 'date-fns/isDate';
import Error from '../../shared/Error';
import { FaLock } from 'react-icons/fa';
import { BiRightArrow } from 'react-icons/bi';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';

const PersonalInfo = ({
  supabase,
  phoneNumber,
  onComplete,
  onFailure,
  rebateAmount,
  instantRebateAmount,
  purchaseAmount,
  totalRebateAmount,
  neuroUserId,
  neuroIdSiteId,
  testMode,
  merchantId,
  env,
}) => {
  // TODO: verify iovationBlackbox integration, since I didn't have access to gdoc.
  const [iovationBlackbox, setIovationBlackbox] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [addressSelected, setAddressSelected] = useState(false);

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'active' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  useEffect(() => {
    // iovation for alloy, see docs: https://help.alloy.com/hc/en-us/articles/4405646102043-iovation-Integration-Device-Data-Aggregation-
    try {
      const bb = ioGetBlackbox();
      setIovationBlackbox(bb.blackbox);
    } catch (error) {
      console.error('Error getting iovation blackbox:', error);
    }
  }, []);

  const handleAddressInputChange = async (inputValue) => {
    try {
      if (inputValue.length > 2) {
        const response = await supabase.functions.invoke('google_maps/autocomplete', {
          body: {
            inputValue,
          },
        });
        if (response) {
          setSuggestions(response.data.predictions);
        } else {
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function fetchZipcode(placeId) {
    try {
      const response = await supabase.functions.invoke('google_maps/zipcode', {
        body: {
          placeId,
        },
      });
      if (response.error) {
        throw new Error(response.error.message || 'Failed to fetch zipcode');
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching zipcode:', error.message);
      throw error;
    }
  }

  const parseAddress = (addressString) => {
    const parts = addressString.split(',').map((part) => part.trim());
    return {
      addressLine1: parts[0] || '',
      city: parts[1] || '',
      state: parts[2] || '',
      country: parts[3] || '',
    };
  };

  const parseSSN = (ssnString) => {
    // Remove hyphens from the SSN string
    const ssnWithoutHyphens = ssnString.replace(/-/g, '');

    // Ensure it contains exactly 9 digits
    if (/^\d{9}$/.test(ssnWithoutHyphens)) {
      return ssnWithoutHyphens;
    }
    return null; // Invalid SSN format
  };

  const onContinuePersonalInfo = async (values) => {
    const parsedAddress = parseAddress(address);
    const parsedSSN = parseSSN(values.ssn);
    // Format dob to YYYY-MM-DD before passing it to the backend
    const parts = values.dob.split('-');
    const formattedDob = `${parts[2]}-${parts[0]}-${parts[1]}`;

    if (testMode) {
      const MOCK_APPLICATION_RESULT = {
        apr: 15,
        financed_amount: 100000,
        purchase_amount_cents: 130000,
        monthly_payment: 10700,
        number_of_payments: 10,
        total_interest_cents: 7000,
        first_payment_date: 'Mon Jan 1 2024',
        instant_rebate_amount_cents: 30000, // for instant rebate
        // rebate_amount_cents: 30000, // for non-instant rebate
      };
      const MOCK_APPLICANT_INFO = {
        firstName: 'Bilbo',
        lastName: 'Baggins',
      };
      onComplete(MOCK_APPLICATION_RESULT, MOCK_APPLICANT_INFO);
      return;
    }

    const applicationResponse = await supabase.functions.invoke('private_new_application', {
      body: {
        firstName: values.firstName,
        lastName: values.lastName,
        ssn: parsedSSN,
        phoneNumber: phoneNumber,
        dob: formattedDob,
        annualIncome: parseInt(values.income),
        email: values.email,
        addressLine1: parsedAddress.addressLine1,
        addressLine2: values.unit,
        city: parsedAddress.city,
        state: parsedAddress.state,
        zip: zipcode,
        purchaseAmountCents: purchaseAmount,
        rebateAmountCents: rebateAmount,
        instantRebateAmountCents: instantRebateAmount,
        iovationBlackbox: iovationBlackbox,
        neuroUserId: neuroUserId,
        neuroIdSiteId: neuroIdSiteId,
        merchantId: merchantId,
        env: env,
      },
    });

    console.log('applicationResponse: ', applicationResponse);

    if (applicationResponse.data.error) {
      // unexpected error (not a denied credit decision)
      setError('unexpected error in application');
    } else if (applicationResponse.data.applicationResult.status === 'denied') {
      // credit denied
      onFailure(applicationResponse.data.applicationResult);
    } else if (applicationResponse.data.applicationResult.status === 'approved') {
      const applicantInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      onComplete(applicationResponse.data.applicationResult, applicantInfo);
    }
  };

  const today = new Date();

  const schema = object({
    firstName: string().min(1).required('First name is required'),
    lastName: string().min(1).required('Last name is required'),
    address: string().min(1, 'Address is required'),

    ssn: string()
      .test(
        'either-digits-or-SSN',
        'SSN must be either 9 digits or in the format XXX-XX-XXXX ',
        (value) => {
          const regexDigits = /^\d{9}$/;
          const regexSSN = /^\d{3}-\d{2}-\d{4}$/;
          return regexDigits.test(value) || regexSSN.test(value);
        },
      )
      .required('SSN is required'),
    email: string().min(1).required('Email is required'),
    income: number()
      .positive('Income should be a positive number')
      .required('Annual income is required')
      .typeError('Income should be a valid number'),
    dob: string()
      .matches(/^\d{2}-\d{2}-\d{4}$/, 'Date of Birth should be in the format MM-DD-YYYY')
      .test('is-valid-date', 'Date of Birth is invalid.', (value) => {
        if (!value) {
          // If there's no value, don't perform the date validation check
          return false;
        }
        const parts = value.split('-');
        if (parts.length === 3) {
          const [month, day, year] = parts;
          const parsedDate = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
          return isDate(parsedDate) && parsedDate <= today;
        }
        return false;
      })
      .required('Date of Birth is required')
      .typeError('Date of Birth should be in the format MM-DD-YYYY'),

    termsAccepted: boolean().oneOf([true], 'You must accept the terms and conditions.'),
  });

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      <StepsCircles steps={STEPS} />

      <br></br>
      <div className="max-w-xl mx-auto">
        <div className="flex flex-col w-full">
          {totalRebateAmount !== 0 && (
            <h1 className="text-black text-3xl text-left font-bold mb-4">
              Your <span className="text-[#48BB78]">{totalRebateAmount}</span> in rebates is
              waiting. Apply to pay in Installments now.
            </h1>
          )}

          <p className="mt-2 text-left text-sm text-gray-600">
            <div className="font-medium text-gray-600">
              Checking your installment eligibility will <span className="underline">NOT</span>{' '}
              affect your credit score.
            </div>
          </p>
          <br></br>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              ssn: '',
              email: '',
              income: '',
              dob: '',
              unit: '',
              termsAccepted: false,
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              setIsLoading(true);
              await onContinuePersonalInfo(values);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="w-full max-w-xl mx-auto">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <Field
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="firstName"
                      placeholder="First name"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                    />
                    <div className="text-red-500">
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="lastName"
                      placeholder="Last name"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                    />
                    <div className="text-red-500">
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <Field name="address" id="address" type="text">
                      {({ field, form, meta }) => (
                        <div>
                          <input
                            type="text"
                            value={address}
                            onChange={(e) => {
                              form.setFieldValue('address', e.target.value);
                              setAddress(e.target.value);
                              handleAddressInputChange(e.target.value);
                            }}
                            placeholder="Address"
                            className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                          />
                          <div>
                            {suggestions.map((suggestion) => (
                              <div
                                key={suggestion.place_id}
                                className="cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                  form.setFieldValue('address', suggestion.description);
                                  setAddress(suggestion.description);
                                  setSuggestions([]);
                                  setAddressSelected(true);
                                  (async () => {
                                    try {
                                      const fetchedZipcode = await fetchZipcode(
                                        suggestion.place_id,
                                      );
                                      setZipcode(fetchedZipcode);
                                    } catch (error) {
                                      console.error('Error fetching zipcode:', error.message);
                                    }
                                  })();
                                }}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Field>

                    <div className="text-red-500">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <Field
                      type="text"
                      name="unit"
                      id="unit"
                      autoComplete="unit"
                      placeholder="Apt, suite, etc (optional)"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                    />
                    {/* <div className="text-red-500">
                      <ErrorMessage name="unit" />
                    </div> */}
                  </div>

                  <div className="sm:col-span-2">
                    <Field
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      placeholder="Email"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                    />
                    <div className="text-red-500">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <Field
                      type="text"
                      name="income"
                      id="income"
                      autoComplete="income"
                      placeholder="Annual income"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                    />
                    <div className="text-red-500">
                      <ErrorMessage name="income" />
                    </div>
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="dob"
                      id="dob"
                      autoComplete="dob"
                      placeholder="Date of Birth (MM-DD-YYYY)"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                      onChange={(e) => {
                        let dobValue = e.target.value;

                        // Remove non-digit characters except for the first two hyphens
                        dobValue = dobValue.replace(/[^0-9-]|-(?![0-9]{2}-[0-9]{2}-)/g, '');

                        // Ensure the hyphens are at the correct positions (MM-DD-YYYY)
                        if (dobValue.length > 2) {
                          if (dobValue.charAt(2) !== '-') {
                            dobValue = dobValue.slice(0, 2) + '-' + dobValue.slice(2);
                          }
                        }
                        if (dobValue.length > 5) {
                          if (dobValue.charAt(5) !== '-') {
                            dobValue = dobValue.slice(0, 5) + '-' + dobValue.slice(5);
                          }
                        }

                        // Limit the length to MM-DD-YYYY (10 characters)
                        if (dobValue.length > 10) {
                          dobValue = dobValue.slice(0, 10);
                        }

                        // Update the dob field's value in the form state
                        setFieldValue('dob', dobValue);
                      }}
                    />

                    <div className="text-red-500">
                      <ErrorMessage name="dob" />
                    </div>
                  </div>

                  <div>
                    <Field
                      type="text"
                      name="ssn"
                      id="ssn"
                      autoComplete="ssn"
                      placeholder="SSN (123-45-6789)"
                      className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                      onChange={(e) => {
                        let ssnValue = e.target.value;

                        // Remove non-digit characters
                        ssnValue = ssnValue.replace(/\D/g, '');

                        // Format the SSN as XXX-XX-XXXX
                        if (ssnValue.length > 3) {
                          ssnValue = ssnValue.slice(0, 3) + '-' + ssnValue.slice(3);
                        }
                        if (ssnValue.length > 6) {
                          ssnValue = ssnValue.slice(0, 6) + '-' + ssnValue.slice(6);
                        }

                        // Limit the length to XXX-XX-XXXX (11 characters)
                        if (ssnValue.length > 11) {
                          ssnValue = ssnValue.slice(0, 11);
                        }

                        // Update the ssn field's value in the form state
                        setFieldValue('ssn', ssnValue);
                      }}
                    />

                    <div className="text-red-500">
                      <ErrorMessage name="ssn" />
                    </div>
                  </div>

                  <div className="mt-1 flex items-center mt-[-10px]">
                    <FaLock className="text-gray-500 w-3 h-5 mr-1" />
                    <p className="text-[#166534] text-x ml-1">Encrypted and never shared</p>
                  </div>
                </div>
                <br />
                <div className="flex items-center mt-4">
                  <Field type="checkbox" name="termsAccepted" id="termsAccepted" className="mr-2" />
                  <label htmlFor="termsAccepted" className="text-[#166534] ml-1">
                    I agree to Upfront's{' '}
                    <span className="underline">
                      {' '}
                      <strong>
                        <a
                          href="https://www.knowupfront.com/terms-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </a>
                      </strong>
                    </span>{' '}
                    ,
                    <span className="underline">
                      {' '}
                      <strong>
                        <a
                          href="https://www.knowupfront.com/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </strong>
                    </span>{' '}
                    ,
                    <span className="underline">
                      {' '}
                      <strong>
                        <a
                          href="https://www.knowupfront.com/e-sign-policy/"
                          className="underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          E-SIGN Consent
                        </a>
                      </strong>
                    </span>{' '}
                    , and our lending partner Pier's{' '}
                    <span className="underline">
                      {' '}
                      <strong>
                        <a
                          href="https://www.pier-lending.com/legal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </a>
                      </strong>
                    </span>
                  </label>
                </div>

                <div className="text-red-500">
                  <ErrorMessage name="termsAccepted" />
                </div>
                <br></br>
                <div className="flex min-h-full items-center justify-center">
                  <ContinueButton isLoading={isLoading} text="Submit Application" />
                </div>
                <div className="flex items-center mt-4">
                  <div className="mt-2 fixed bottom-2 mr-10 text-xs text-left text-gray-600">
                    By continuing, you consent to our using of consumer reporting agencies, like
                    Experian and Equifax, to make a soft credit inquiry and to verify your
                    employment and income. Your loan is provided by our lending partner, Pier
                    Lending LLC NMLS# 2451164.
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
