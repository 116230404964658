import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import alloy from '@alloyidentity/web-sdk';
import { object, string, number, date } from 'yup';
import { BiRightArrow } from 'react-icons/bi';
import Error from '../../shared/Error';
import StepsCircles from './Components/StepsCircles';
import { FaSpinner } from 'react-icons/fa';
import { ContinueButton } from './Components/ContinueButton';

const ZipCode = ({
  supabase,
  merchantProductId,
  merchantId,
  onComplete,
  onFailure,
  setNeuroUserId,
  setNeuroIdSiteId,
  env,
}) => {
  const [error, setError] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const STEPS = [
    { name: '1. Your Rebates', status: 'active' },
    { name: '2. Apply', status: '' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  // map from merchantProductId to productId
  useEffect(() => {
    async function fetchProductId() {
      const productIdResponse = await supabase.functions.invoke('private_get_product_id', {
        body: {
          merchantProductId,
          merchantId,
        },
      });
      setProductId(productIdResponse.data.productId);
    }
    fetchProductId();

    // Neuro-ID for Alloy, see integration guide here: https://developer.alloy.com/public/docs/neuroid-integration
    const alloyInitParams = {
      key: process.env.REACT_APP_ALLOY_SDK_SANDBOX_KEY,
      journeyToken: process.env.REACT_APP_ALLOY_JOURNEY_SANDBOX_TOKEN,
      isNext: true,
      color: { primary: '#CD7D2D', secondary: '#862633' },
    };
    alloy
      .init(alloyInitParams)
      .then((alloyResponse) => {
        setNeuroUserId(alloyResponse.neuroUserId);
        setNeuroIdSiteId(alloyResponse.neuroIdSiteId);
      })
      .catch((error) => {
        console.error('Error initializing Alloy:', error);
      });
  }, []);

  const checkValidZip = async (zip) => {
    try {
      const zipInfo = await supabase.functions.invoke('check_zip', {
        body: {
          zip,
          env,
        },
      });
      console.log('zipInfo', zipInfo);
      return zipInfo.data.isValidZip; // Return the validity
    } catch (error) {
      console.error('Error:', error);
      return false; // Return false in case of an error
    }
  };

  const onSubmitZip = async (values, isValid) => {
    const qualificationResponse = await supabase.functions.invoke(
      'private_get_qualification_questions',
      {
        body: {
          zip: values.zipCode,
          productId: productId,
        },
      },
    );
    console.log('qualificationResponse data', qualificationResponse.data);
    if (isValid) {
      onComplete(
        qualificationResponse.data.questions,
        qualificationResponse.data.eligiblePrograms,
        values.zipCode,
      );
    } else {
      onFailure();
    }
  };

  const validationSchema = object({
    zipCode: string()
      .min(5, 'Zip code must be 5 characters')
      .max(5, 'Zip code must be 5 characters')
      .required('Zip code is required'),
  });

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      <StepsCircles steps={STEPS} />

      <br></br>
      <h1 className="text-black text-4xl text-left font-bold mb-4">
        Pay with Installments that Work for You
      </h1>
      <p className="mt-2 text-left text-lg text-gray-600">
        <a href="#" className="font-large text-gray-600">
          Government and utility rebates help you save money.
        </a>
      </p>

      <ul>
        <li className="flex py-2">
          <img className="ml-10 mr-10 h-20" src="Rebates.png" alt="Rebates" />
          <span className="self-center mr-10">
            <b>Your rebates, your money</b>
            <br />
            We find rebates that you are owed to put money back in your pocket.
          </span>
        </li>
        <li className="flex py-2">
          <img className="ml-10 mr-10 h-20" src="Paperwork.png" alt="Paperwork" />
          <span className="self-center mr-10">
            <b>We do the paperwork</b>
            <br />
            Your Upfront dashboard makes processing tricky rebates clear and easy.
          </span>
        </li>
        <li className="flex py-2">
          <img className="ml-10 mr-10 h-20" src="Surprise.png" alt="Surprise" />
          <span className="self-center mr-10">
            <b>No surprises</b>
            <br />
            Be approved for installments instantly and <b>never</b> pay early payment or late fees.
          </span>
        </li>
      </ul>

      <div className="border-t border-gray-200 my-6"></div>

      <h1 className="text-black text-3xl text-left font-bold mb-4">Let's find your rebates</h1>
      <p className="mt-2 text-left text-lg text-gray-600">
        <a href="#" className="font-medium text-gray-600">
          Tell us where this is going to be installed. Enter your zip below:
        </a>
      </p>

      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (validationSchema.isValidSync({ zipCode: values.zipCode })) {
            setIsLoading(true);
            const isValid = await checkValidZip(values.zipCode);
            onSubmitZip(values, isValid); //hardcode isValid to true for testing if getting "not supported for this state" -> UFP-202
          }
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="login-form">
            <div className="mt-4">
              <Field
                type="text"
                name="zipCode"
                id="zipCode"
                autoComplete="zipCode"
                placeholder="Zip code"
                disabled={!productId}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:text-sm"
                onChange={(e) => {
                  const zip = e.target.value;
                  setFieldValue('zipCode', zip); // Update the value in Formik's state
                }}
              />
              <div className="text-red-500">
                <ErrorMessage name="zipCode" />
              </div>
            </div>
            {error ? <Error message={error} /> : null}
            <div className="button-container fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
              <div className="w-full mb-2">
                <ContinueButton isLoading={isLoading} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    // </div>
  );
};

export default ZipCode;
