import React, { useState } from 'react';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import { Loading } from './Components/Loading';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { bool, object } from 'yup';
import { PreviousButton } from './Components/PreviousButton';

const SignLoan = ({
  supabase,
  loanPdfURL,
  loanAgreementResponse,
  onComplete,
  onBack,
  testMode,
  eligiblePrograms,
  programs,
  merchantId,
  installationPrice,
  productPrice,
  env,
}) => {
  console.log('loanAgreementResponse document_id', loanAgreementResponse.document_id);

  const [isLoading, setIsLoading] = useState(false);

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'completed' },
    { name: '3. Approval', status: 'active' },
    { name: '4. Finish Up', status: '' },
  ];

  const schema = object({
    termsAccepted: bool().oneOf([true], 'You must read and agree to the loan agreement'),
  });

  const onContinueSign = async () => {
    if (testMode) {
      onComplete('doc_test_loan_id', 'borrower_test_id');

      // notify upfront.js that loan is created successfully
      window.parent.postMessage(
        {
          message: 'upfront pay success',
          loan_id: 'doc_test_loan_id',
        },
        '*',
      );
      return;
    }

    const servicingDashboardData = async (signResponse) => {
      const programObjects = eligiblePrograms.map((id) => ({ id }));

      // write loan, incentive, tasks data to tables
      const applicationResponse = await supabase.functions.invoke(
        'private_create_tasks_for_programs',
        {
          body: {
            programs: programObjects,
            loan_id: signResponse.data.loan_id,
            productPrice: productPrice,
            installationPrice: installationPrice,
            programData: programs,
          },
        },
      );
      console.log('private_create_tasks_for_programs reply', applicationResponse);
    };

    const signResponse = await supabase.functions.invoke('private_sign_loan_agreement', {
      body: {
        loanAgreementId: loanAgreementResponse.document_id,
        merchantId: merchantId,
        env: env,
      },
    });
    console.log('signreply', signResponse);

    servicingDashboardData(signResponse);

    if (signResponse.error) {
      // TODO: handle the error in the UI.
      console.error('unexpected error in application');
    } else {
      onComplete(signResponse.data.loan_id, signResponse.data.borrower_id);

      // notify upfront.js that loan is created successfully
      window.parent.postMessage(
        {
          message: 'upfront pay success',
          loan_id: signResponse.data.loan_id,
        },
        '*',
      );
    }
  };

  const pdfZoomedOutUrl = `${loanPdfURL}#zoom=70`;

  return (
    <div>
      <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
        <StepsCircles steps={STEPS} />
        <br></br>

        <div className="max-w-xl mx-auto flex flex-col h-[calc(100vh-200px)]">
          <h1 className="text-black text-3xl md:text-4xl font-bold mb-4 text-left">
            Review Your Documents
          </h1>
          <div className="max-w-xl mx-auto">
            <iframe
              src={pdfZoomedOutUrl}
              width="75%"
              style={{
                border: 'none',
                flexGrow: 1,
                minHeight: '500px',
                minWidth: '500px',
              }}
              title="Loan Agreement"
            ></iframe>
          </div>

          <Formik
            initialValues={{
              termsAccepted: false,
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setIsLoading(true);
              onContinueSign();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex items-center mt-4">
                  <Field name="termsAccepted" id="termsAccepted" type="checkbox" />
                  <label htmlFor="termsAccepted" className="text-[#166534] flex-shrink-0">
                    I have read and agree to the details outlined in the loan agreement and by
                    clicking this I sign it.
                  </label>
                </div>
                <div className="text-red-500">
                  <ErrorMessage name="termsAccepted" />
                </div>
                <br></br>

                <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
                  <div className="w-full mb-2">
                    <ContinueButton isLoading={isLoading} />
                    <PreviousButton isLoading={isLoading} onClick={onBack} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignLoan;
