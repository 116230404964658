import React from 'react';
import { createClient } from '@supabase/supabase-js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Title from '../shared/Title';
import { object, string, number } from 'yup';
import PhoneInput from 'react-phone-input-2';
import PinInput from 'react-pin-input';
import 'react-phone-input-2/lib/style.css';
import Error from '../shared/Error';
import Steps from '../shared/Steps';
// import { loadUpfrontjs } from '@knowupfront/upfrontjs';

const VALID_FOR = 24;

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

class Qualification extends React.Component {
  constructor(props) {
    super(props);

    // Create a single supabase client for interacting with your database
    let supabase;
    if (process.env.NODE_ENV == 'development' && false) {
      supabase = createClient('http://localhost:54321', 'replace_with_dev_token');
    } else {
      console.log(process.env.REACT_APP_SUPABASE_AUTH_KEY);
      supabase = createClient(
        'https://uvslaluyawuujqysrzuj.supabase.co',
        process.env.REACT_APP_SUPABASE_AUTH_KEY,
      );
    }
    this.state = {
      supabase,
      ACTIVE_FLOW: 'ecommerce',
      currentPage: null,
      flows: {
        ecommerce: {
          zipCode: {
            nextSuccess: 'qualification',
            nextFailure: 'declined',
          },
          qualification: {
            nextSuccess: 'success',
            nextFailure: 'declined',
          },
          success: {
            nextSuccess: 'phone',
          },
          explainer: {
            nextSuccess: 'zipCode',
          },
          phone: {
            nextSuccess: 'pin',
          },
          pin: {
            nextSuccess: 'confirmationEcommerce',
          },
          card: {
            // removed for now to reduce friction in shopify test
            nextSuccess: 'confirmationEcommerce',
          },
        },
      },
      formIndex: 0, // todo change to be an explicit pointer to the page
      formPages: [
        'apiKey',
        'product',
        'zipCode',
        'qualification',
        'declined',
        'success',
        'explainer',
        'phone',
        'pin',
        'card',
        'confirmationEcommerce',
        'confirmation',
      ],
      qualificationId: null,
      yesSelected: {},
      noSelected: {},
      qualificationQuestions: null,
      merchantId: null,
      zipCode: null,
      products: null,
      productId: null,
      productPrice: null,
      installationPrice: null,
      formError: null,
      eligiblePrograms: null,
      programs: null,
      fee: null,
      isIFrame: false,
      accepted: null,
      declineSubmitted: false,
      declinedTitle: null,
      declinedReason: null,
      loadingZip: false,
      loadingQualification: false,
      phoneNumber: null,
      userId: null,
      failedPinError: null,
      testMode: null,
      productName: null,
      alreadyQualified: false,
    };
  }

  componentDidMount = async () => {
    // demo -- this is how you load the ES module and it works!
    // const upfrontjs = await loadUpfrontjs();
    // console.log(upfrontjs);

    const merchantId = this.props.searchParams[0].get('merchantId')
      ? Number(this.props.searchParams[0].get('merchantId'))
      : null;

    // if here, gp straight to success page
    const qualificationId = Number(this.props.searchParams[0].get('qualificationId'));
    if (qualificationId) {
      this.setState({ loadingQualification: true });
      const qualificationResponse = await this.state.supabase.functions.invoke(
        'private_qualification',
        {
          body: {
            qualificationId,
          },
        },
      );
      console.log(qualificationResponse);

      const productNameResponse = await this.state.supabase.functions.invoke(
        'private_get_product_name',
        {
          body: {
            productId: qualificationResponse.data.equipment_id,
            merchantId,
          },
        },
      );

      if (qualificationResponse.data.eligible_programs.length === 0) {
        this.setState({
          isIFrame: true,
          loadingQualification: false,
          currentPage: 'declined',
          declinedTitle: 'You don’t qualify for instant rebates based on the information provided.',
          declinedReason:
            'If you live in the US, you’re still eligible for the U.S. federal tax credit for 30% of your EV charger plus installation costs (up to $1,000)',
        });
      } else {
        this.setState({
          isIFrame: true,
          qualificationId,
          alreadyQualified: true,
          productName: productNameResponse.data.productName,
          loadingQualification: false,
          currentPage: 'success',
          productPrice: qualificationResponse.data.product_amount,
          installationPrice: qualificationResponse.data.installation_amount,
          productDiscount: qualificationResponse.data.product_discount,
          installationDiscount: qualificationResponse.data.installation_discount,
          programs: qualificationResponse.data.eligible_programs,
          fee: qualificationResponse.data.consumer_fee,
        });
      }

      return;
    }

    // not used right now
    const stripe = await loadStripe(
      'pk_test_51MPVGOFJQcpYcJ9vMSaLk8c8TFi6CPFBswLG2RiTP44ZrxuAR9ZH4To8LeLt9sp7b05jfms4WPm2XxYbMuO5dO7J00LWch7CJk',
    );

    this.setState({ stripe });

    const testMode = this.props.searchParams[0].get('testMode');
    console.log(testMode);
    let productId;
    if (testMode) {
      // test the fields that the developer shouldn't have to care about. Test mode will also use a mock SMS pin for the logins
      this.setState({ testMode: true });
      productId = 76;
    } else {
      // log merchant id and product id
      console.log('merchant id', merchantId); // todo set merchant id to protect against merchants with duplicate product ids
      console.log('product id', this.props.searchParams[0].get('productId'));
      productId = this.props.searchParams[0].get('productId');

      // map from the merchant product id to the upfront product id
      const { data, error } = await this.state.supabase
        .from('merchant_equipment_junction')
        .select()
        .eq('merchant_equipment_id', String(productId));
      console.log(data);
      console.log(error);
      productId = data[0].equipment_id;
    }

    const zipCode = this.props.searchParams[0].get('zipCode');
    let productPrice = Number(this.props.searchParams[0].get('productPrice'));
    let installationPrice = Number(this.props.searchParams[0].get('installationPrice'));

    // temporary hack for neocharge price bug
    // let overridePrice = Number(this.props.searchParams[0].get('overridePrice'));
    // if (overridePrice) {
    //     productPrice = overridePrice;
    // }

    const isInstallationHardware = this.props.searchParams[0].get('isInstallationHardware');
    if (isInstallationHardware) {
      installationPrice = productPrice;
      productPrice = 0;
    }

    // included if any included

    if (zipCode) {
      // currently broken but not used
      this.setState({ formIndex: 3, isIFrame: true });
      const qualificationResponse = await this.state.supabase.functions.invoke(
        'private_get_qualification_questions',
        {
          body: {
            zip: zipCode,
            productId,
            merchantId,
          },
        },
      );
      console.log(qualificationResponse);

      // if no programs in the zip, go straight to declined page
      if (qualificationResponse.data.eligiblePrograms.length === 0) {
        this.setState({ currentPage: 'declined' });
      } else {
        this.setState({
          currentPage: 'qualification',
          zipCode,
          productId,
          merchantId,
          productPrice,
          installationPrice,
          qualificationQuestions: qualificationResponse.data.questions,
          eligiblePrograms: qualificationResponse.data.eligiblePrograms,
        });
      }
    } else {
      this.setState({
        isIFrame: true,
        currentPage: 'explainer',
        productId,
        productPrice,
        installationPrice,
        merchantId,
      });
      const productNameResponse = await this.state.supabase.functions.invoke(
        'private_get_product_name',
        {
          body: {
            productId,
            merchantId,
          },
        },
      );
      this.setState({ productName: productNameResponse.data.productName });
    }
  };

  getNextSuccessPage = () => {
    return this.state.flows[this.state.ACTIVE_FLOW][this.state.currentPage].nextSuccess;
  };

  getNextFailurePage = () => {
    return this.state.flows[this.state.ACTIVE_FLOW][this.state.currentPage].nextFailure;
  };

  saveZipCode = async (values) => {
    // i think can just do getNextSuccessPage on here and next failure if it fails
    this.setState({ currentPage: this.getNextSuccessPage(), loadingZip: true });
    const qualificationResponse = await this.state.supabase.functions.invoke(
      'private_get_qualification_questions',
      {
        body: {
          zip: values.zipCode,
          productId: this.state.productId,
          merchantId: this.state.merchantId,
        },
      },
    );
    console.log(qualificationResponse);

    if (qualificationResponse.data.error) {
      this.setState({
        loadingZip: false,
        currentPage: 'zipCode',
        formError: qualificationResponse.data.error,
      });
    } else if (qualificationResponse.data.eligiblePrograms.length === 0) {
      this.setState({
        loadingZip: false,
        currentPage: 'declined',
        declinedTitle: 'No rebates available in your region.',
        declinedReason:
          'If you live in the US, you’re still eligible for the U.S. federal tax credit for 30% of your EV charger plus installation costs (up to $1,000)',
      });
    } else {
      this.setState({
        loadingZip: false,
        zipCode: values.zipCode,
        qualificationQuestions: qualificationResponse.data.questions,
        eligiblePrograms: qualificationResponse.data.eligiblePrograms,
      });
    }
  };

  saveProduct = async (values) => {
    const productId = Number(values.productId) || Number(this.state.products[0].id);

    const product = this.state.products.find((p) => p.id === productId);
    this.setState({
      productId,
      productPrice: product.price,
      installationPrice: 0,
      currentPage: this.getNextSuccessPage(),
    });
  };

  saveApiKey = async (values) => {
    this.setState({ currentPage: this.getNextSuccessPage() });
    const apiKey = values.apiKey;

    const merchantResponse = await this.state.supabase.functions.invoke('private_login_merchant', {
      body: {
        apiKey,
      },
    });

    if (merchantResponse.data.merchant) {
      this.setState({ merchantId: merchantResponse.data.merchant });

      const productResponse = await this.state.supabase.functions.invoke(
        'private_products_for_merchant',
        {
          body: {
            merchantId: merchantResponse.data.merchant,
          },
        },
      );
      this.setState({ products: productResponse.data.products });
    } else {
      this.setState({ currentPage: 'apiKey', formError: 'invalid API key' });
    }
  };

  saveQualification = async (values) => {
    this.setState({
      currentPage: this.getNextSuccessPage(),
      loadingQualification: true,
    });
    const qualificationResponse = await this.state.supabase.functions.invoke(
      'private_get_eligible_programs',
      {
        body: {
          qualificationData: values,
          eligiblePrograms: this.state.eligiblePrograms,
          productPrice: this.state.productPrice,
          installationPrice: this.state.installationPrice,
        },
      },
    );

    const qualification = {
      zip_code: this.state.zipCode,
      product_amount: this.state.productPrice,
      installation_amount: this.state.installationPrice,
      product_discount: qualificationResponse.data.productDiscount,
      installation_discount: qualificationResponse.data.installationDiscount,
      eligible_programs: qualificationResponse.data.programs,
      qualification_answers: values,
      merchant_id: this.state.merchantId,
      equipment_id: this.state.productId,
      consumer_fee: qualificationResponse.data.consumer_fee,
    };
    const { data } = await this.state.supabase.from('qualification').insert(qualification).select();
    this.setState({ qualificationId: data[0].id });

    const isDeclined = qualificationResponse.data.programs.length === 0;
    if (isDeclined) {
      this.setState({
        loadingQualification: false,
        currentPage: 'declined',
        declinedTitle: 'You don’t qualify for rebates',
        declinedReason:
          'If you live in the US, you’re still eligible for the U.S. federal tax credit for 30% of your EV charger plus installation costs (up to $1,000)',
      });
    } else {
      this.setState({
        loadingQualification: false,
        productDiscount: qualificationResponse.data.productDiscount,
        installationDiscount: qualificationResponse.data.installationDiscount,
        programs: qualificationResponse.data.programs,
        fee: qualificationResponse.data.consumer_fee,
      });
    }
  };

  onBackWebsite = () => {
    window.parent.postMessage(
      {
        message: 'close modal',
      },
      '*',
    );
  };

  onConfirm = () => {
    this.setState({ accepted: true });
  };

  onConfirmEcommerce = () => {
    this.setState({ currentPage: this.getNextSuccessPage() });
  };

  onCancelQualification = async () => {
    const cancelResponse = await this.state.supabase.functions.invoke(
      'private_cancel_qualification',
      {
        body: {
          qualificationId: this.state.qualificationId,
        },
      },
    );
    console.log(cancelResponse);

    window.parent.postMessage(
      {
        message: 'cancel qualification',
        qualificationId: this.state.qualificationId,
      },
      '*',
    );
  };

  onSubmitFeedback = async (values) => {
    await this.state.supabase.functions.invoke('private_decline_feedback', {
      body: {
        text: values.feedback,
        qualificationId: this.state.qualificationId,
      },
    });
    this.setState({ declineSubmitted: true });
  };

  onSubmit = async (values) => {
    this.setState({ currentPage: this.getNextSuccessPage() });
    const purchaseResponse = await this.state.supabase.functions.invoke('private_submit_purchase', {
      body: {
        name: values.name,
        phoneNumber: values.phoneNumber,
        qualificationId: this.state.qualificationId,
      },
    });
  };

  sendPin = async (values) => {
    this.setState({ phoneNumber: values.phoneNumber });
    console.log(this.state.testMode);
    if (!this.state.testMode) {
      console.log('sending pin');
      const { data, error } = await this.state.supabase.auth.signInWithOtp({
        phone: values.phoneNumber,
      });
    }
    this.setState({ currentPage: this.getNextSuccessPage() });
  };

  loginUser = async (values) => {
    if (!this.state.testMode) {
      const { error: optError } = await this.state.supabase.auth.verifyOtp({
        phone: this.state.phoneNumber,
        token: values.pinNumber,
        type: 'sms',
      });
      if (optError) {
        return;
      }
    }

    const newUser = {
      phone_number: this.state.phoneNumber,
      created_at: new Date(),
      is_test_mode: this.state.testMode,
    };
    let { data, error } = await this.state.supabase.from('consumer').insert(newUser).select();

    // user phone number already exists, look-up user from DB
    if (error) {
      ({ data, error } = await this.state.supabase
        .from('consumer')
        .select()
        .eq('phone_number', this.state.phoneNumber));
    }
    const userId = data[0].id;

    // attach qualification to this user id
    await this.state.supabase
      .from('qualification')
      .update({ consumer_id: userId })
      .eq('id', this.state.qualificationId);
    this.setState({ userId });

    // tell Upfront.js that it's qualified
    window.parent.postMessage(
      {
        message: {
          qualificationId: this.state.qualificationId,
        },
      },
      '*',
    );

    return userId;
  };

  setupStripePayment = async () => {
    const { data } = await this.state.supabase.functions.invoke('private_get_setup_intent'); // currently using sandbox mode for stripe all the time
    const { clientSecret } = data;

    const appearance = {
      theme: 'flat',
      variables: {
        colorPrimary: '#166534',
        colorText: '#000000',
      },
    };
    const elements = this.state.stripe.elements({ appearance, clientSecret });
    this.setState({ elements });

    const paymentElementOptions = {
      layout: 'tabs',
    };
    const paymentElement = elements.create('payment', paymentElementOptions);
    paymentElement.mount('#payment-element');
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { elements } = this.state;

    const { setupIntent, error: stripeError } = await this.state.stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    });

    if (stripeError) {
      if (stripeError.type === 'card_error' || stripeError.type === 'validation_error') {
        this.setState({ stripeError: stripeError.message });
      } else {
        this.setState({
          stripeError: 'Unknown payment error. Please try again or use a different payment method.',
        });
      }
      return;
    }

    await this.state.supabase
      .from('consumer')
      .update({ stripe_setup_intent_client_secret: setupIntent.client_secret })
      .eq('id', this.state.userId);

    this.setState({ currentPage: this.getNextSuccessPage() });
  };

  onDeclineRebates = () => {
    this.setState({ currentPage: this.getNextFailurePage(), accepted: false });
  };

  onDone = () => {
    window.location.reload();
  };

  getStepData = () => {
    const { currentPage } = this.state;
    if (currentPage == 'apiKey') {
      return [
        { id: '01', name: 'API Key', status: 'current' },
        { id: '02', name: 'Product', status: 'upcoming' },
        { id: '03', name: 'Zip', status: 'upcoming' },
        { id: '04', name: 'Qualification', status: 'upcoming' },
        { id: '05', name: 'Confirmation', status: 'upcoming' },
      ];
    } else if (currentPage == 'product') {
      return [
        {
          id: '01',
          name: 'API Key',
          onClick: () => this.setState({ formIndex: 0 }),
          status: 'complete',
        }, // todo decide if need this
        { id: '02', name: 'Product', status: 'current' },
        { id: '03', name: 'Zip', status: 'upcoming' },
        { id: '04', name: 'Qualification', status: 'upcoming' },
        { id: '05', name: 'Confirmation', status: 'upcoming' },
      ];
    } else if (currentPage == 'zipCode') {
      return [
        {
          id: '01',
          name: 'API Key',
          onClick: () => this.setState({ formIndex: 0 }),
          status: 'complete',
        },
        {
          id: '02',
          name: 'Product',
          onClick: () => this.setState({ formIndex: 1 }),
          status: 'complete',
        },
        { id: '03', name: 'Zip', status: 'current' },
        { id: '04', name: 'Qualification', status: 'upcoming' },
        { id: '05', name: 'Confirmation', status: 'upcoming' },
      ];
    } else if (currentPage == 'qualification') {
      return [
        {
          id: '01',
          name: 'API Key',
          onClick: () => this.setState({ formIndex: 0 }),
          status: 'complete',
        },
        {
          id: '02',
          name: 'Product',
          onClick: () => this.setState({ formIndex: 1 }),
          status: 'complete',
        },
        {
          id: '03',
          name: 'Zip',
          onClick: () => this.setState({ formIndex: 2 }),
          status: 'complete',
        },
        { id: '04', name: 'Qualification', status: 'current' },
        { id: '05', name: 'Confirmation', status: 'upcoming' },
      ];
    } else if (
      currentPage == 'declined' ||
      currentPage == 'success' ||
      currentPage == 'confirmation'
    ) {
      return [
        {
          id: '01',
          name: 'API Key',
          onClick: () => this.setState({ formIndex: 0 }),
          status: 'complete',
        },
        {
          id: '02',
          name: 'Product',
          onClick: () => this.setState({ formIndex: 1 }),
          status: 'complete',
        },
        {
          id: '03',
          name: 'Zip',
          onClick: () => this.setState({ formIndex: 2 }),
          status: 'complete',
        },
        {
          id: '04',
          name: 'Qualification',
          onClick: () => this.setState({ formIndex: 3 }),
          status: 'complete',
        },
        { id: '05', name: 'Confirmation', status: 'current' },
      ];
    }
  };

  render() {
    const { currentPage } = this.state;

    const continueButtonClass =
      'group bottom-3 flex w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2';

    if (currentPage == 'apiKey') {
      if (this.state.loadingQualification) {
        return (
          <div>
            <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-full space-y-8">
                <span className="group relative flex w-full justify-center py-2 px-4 text-medium font-medium">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        );
      }

      const obj = {
        apiKey: string().min(1, 'Api key must be 1 digits').required('Api key is required'),
      };
      const schema = object(obj);

      return (
        <div>
          <Steps steps={this.getStepData()} />
          <Title heading={'Merchant API Key'} />
          <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <Formik
              initialValues={{}}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                this.saveApiKey(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="login-form large-margin-top">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                    <label
                      htmlFor="apiKey"
                      className="block text-l font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      What is your merchant API key?
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="apiKey"
                        id="apiKey"
                        autoComplete="apiKey"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:max-w-xs sm:text-sm"
                      />
                      <ErrorMessage name="apiKey" />
                    </div>
                  </div>
                  <br />
                  {this.state.formError ? <Error message={this.state.formError} /> : null}
                  <br />
                  <div className="flex min-h-full items-center justify-center">
                    <button
                      type="submit"
                      className="group absolute bottom-3 flex w-3/4 lg:w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <br />
            <br />
          </div>
        </div>
      );
    } else if (currentPage == 'product') {
      if (this.state.products == null) {
        return (
          <div>
            <Steps steps={this.getStepData()} />
            <Title heading={'Product'} />
            <br />
            <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-full space-y-8">
                <span className="group relative flex w-full justify-center py-2 px-4 text-medium font-medium">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        );
      }

      const obj = {
        productId: number(),
      };
      const schema = object(obj);

      return (
        <div>
          <Steps steps={this.getStepData()} />
          <Title heading={'Product'} />
          <div className="lg:w-1/2 lg:mx-auto">
            <Formik
              initialValues={{}}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                this.saveProduct(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="login-form large-margin-top">
                  <div
                    key="productId"
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5"
                  >
                    <label
                      htmlFor="productId"
                      className="block text-l font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      EVSE Products
                    </label>
                    <Field
                      as="select"
                      name="productId"
                      class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {this.state.products.map((product) => {
                        return (
                          <option value={product.id}>
                            {product.manufacturer + ': ' + product.model}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage name="productId" />
                  </div>
                  <br />
                  <div className="flex min-h-full items-center justify-center">
                    <button
                      type="submit"
                      className="group absolute bottom-3 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    } else if (currentPage == 'confirmationEcommerce') {
      return (
        <div>
          <Title heading="You’re all set! Happy savings" />
          <div className="mt-20 flex-col min-h-full items-center justify-center">
            <div className="md:flex sm:items-center sm:justify-between ml-10 mr-10">
              <ul className="divide-y divide-gray-200">
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-checkout.png" />
                  <span className="self-center mr-10">
                    Proceed to checkout, and you’ll see your Upfront discount automatically applied
                  </span>
                </li>
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-24-hours.png" />
                  <span className="self-center mr-10">
                    The Upfront discount is valid for 24 hours
                  </span>
                </li>
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-sms.png" />
                  <span className="self-center mr-10">
                    After checkout, we will follow up with you to help you process and receive the
                    remainder of your rebate
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex min-h-full items-center justify-center">
            <button type="submit" onClick={this.onBackWebsite} className={continueButtonClass}>
              Continue to Checkout
            </button>
          </div>
        </div>
      );
    } else if (currentPage == 'explainer') {
      const rebatesToday = moneyFormatter.format(
        (this.state.productDiscount + this.state.installationDiscount) / 100 / 2,
      );
      const rebatesAfterInstall = rebatesToday;
      return (
        <div>
          <Title heading="Save $100s Today with Upfront" />
          <div className="mt-20 flex-col min-h-full items-center justify-center">
            <div className="md:flex sm:items-center sm:justify-between ml-10 mr-10">
              <ul className="divide-y divide-gray-200">
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-signup.png" />
                  <span className="self-center mr-10">
                    Enter your ZIP code and answer a few questions so we can find rebates that
                    you're eligible for
                  </span>
                </li>
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-calendar-next.png" />
                  <span className="self-center mr-10">
                    Immediately enjoy 50% of your rebate savings, and get the rest once your rebate
                    application is completed
                  </span>
                </li>
                <li className="flex py-4">
                  <img className="ml-10 mr-10 h-20" src="noun-discount.png" />
                  <span className="self-center mr-10">
                    Sign up using just your phone number and proceed to checkout with your savings
                    automatically applied
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex min-h-full items-center justify-center">
            <button
              type="submit"
              onClick={() => this.setState({ currentPage: this.getNextSuccessPage() })}
              className={continueButtonClass}
            >
              Continue
            </button>
          </div>
        </div>
      );
    } else if (currentPage == 'zipCode') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      const obj = {
        zipCode: string().min(5, 'Zip code must be 5 digits').required('Zip is required'),
      };
      const schema = object(obj);

      return (
        <div>
          {steps}
          <Title heading={'Location'} />
          <div className="flex-col min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <p className="mt-2 text-center text-medium text-gray-600">
              <div className="font-medium text-[#166534]">
                To find you rebates, where is this going to be installed?
              </div>
            </p>
            <Formik
              initialValues={{}}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                this.saveZipCode(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="ml-10 mr-10 login-form large-margin-top">
                  <div className="flex items-center justify-center pt-8">
                    <div>
                      <Field
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        placeholder="Zip code"
                        autoComplete="zipCode"
                        className="block max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:max-w-xs sm:text-sm"
                      />
                      <ErrorMessage name="zipCode" />
                    </div>
                  </div>
                  <br />
                  {this.state.formError ? <Error message={this.state.formError} /> : null}
                  <br />
                  <div className="flex min-h-full items-center justify-center">
                    <button type="submit" className={continueButtonClass}>
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    } else if (currentPage == 'qualification') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      if (this.state.qualificationQuestions == null || this.state.loadingZip) {
        return (
          <div>
            {steps}
            <Title heading={'Qualification'} />
            <br />
            <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-full space-y-8">
                <span className="group relative flex w-full justify-center py-2 px-4 text-medium font-medium">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        );
      }

      const dynamicQuestions = this.state.qualificationQuestions.map((q) => {
        if (q.type == 'boolean') {
          return (
            <div
              key={q.id}
              className="justify-around sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5"
            >
              <label
                htmlFor={q.name}
                className="col-span-2 block text-l font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                {q.full_text}
              </label>
              <Field
                name={q.name}
                id={q.name}
                type="button"
                className="col-span-1 isolate inline-flex rounded-md shadow-sm"
              >
                {({ field, form, meta }) => (
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        form.setFieldValue(field.name, true);
                        this.setState({
                          yesSelected: {
                            ...this.state.yesSelected,
                            [q.name]: true,
                          },
                          noSelected: {
                            ...this.state.noSelected,
                            [q.name]: false,
                          },
                        });
                      }}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none"
                      style={
                        this.state.yesSelected[q.name]
                          ? { backgroundColor: '#166534', color: '#ffffff' }
                          : {}
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        form.setFieldValue(field.name, false);
                        this.setState({
                          yesSelected: {
                            ...this.state.yesSelected,
                            [q.name]: false,
                          },
                          noSelected: {
                            ...this.state.noSelected,
                            [q.name]: true,
                          },
                        });
                      }}
                      className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
                      style={
                        this.state.noSelected[q.name]
                          ? { backgroundColor: '#166534', color: '#ffffff' }
                          : {}
                      }
                    >
                      No
                    </button>
                  </div>
                )}
              </Field>
              <ErrorMessage name={q.name} />
            </div>
          );
        }
      });

      const obj = {};
      this.state.qualificationQuestions.forEach((q) => {
        if (q.type == 'boolean') {
          obj[q.name] = string().required(`${q.full_text} is required}`);
        }
      });
      const schema = object(obj);

      // mapping between my own internal state for buttons and formik
      const initialValues = {};
      for (const [k, v] of Object.entries(this.state.yesSelected)) {
        if (v === true) {
          initialValues[k] = true;
        }
      }
      for (const [k, v] of Object.entries(this.state.noSelected)) {
        if (v === true) {
          initialValues[k] = false;
        }
      }

      return (
        <div className="ml-10 mr-10">
          {steps}
          <Title heading={'Qualification'} />
          <p className="mt-2 text-center text-medium text-gray-600">
            <div className="font-medium text-[#166534]">
              Just a few questions to check your rebate eligibility.
            </div>
          </p>
          <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                this.saveQualification(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="login-form large-margin-top">
                  {dynamicQuestions}
                  <br />
                  <div className="flex min-h-full items-center justify-center">
                    <button type="submit" className={continueButtonClass}>
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    } else if (currentPage == 'declined') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      let buttons;
      if (!this.state.isIFrame) {
        buttons = (
          <div className="flex min-h-full items-center justify-center my-5">
            <button
              type="submit"
              onClick={this.onDone}
              className="group absolute bottom-3 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Start over
            </button>
          </div>
        );
      } else {
        buttons = (
          <div className="flex min-h-full items-center justify-center">
            <button
              type="submit"
              onClick={this.onBackWebsite}
              className="group absolute bottom-3 flex w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Back to Website
            </button>
          </div>
        );
      }

      return (
        <div className="text-center align-middle">
          {steps}
          <Title heading={this.state.declinedTitle} />
          <h1 className="ml-10 mr-10 mt-2 text-lg font-medium text-[#166534]">
            {this.state.declinedReason}
          </h1>
          {buttons}
        </div>
      );
    } else if (currentPage == 'success') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      const fullPrice = moneyFormatter.format(
        (this.state.productPrice + this.state.installationPrice) / 100,
      );
      const totalSavings = moneyFormatter.format(
        (this.state.productDiscount + this.state.installationDiscount - this.state.fee) / 100,
      );
      const rebatesToday = moneyFormatter.format(
        (this.state.productDiscount + this.state.installationDiscount) / 100 / 2,
      ); // 50% upfront today
      const rebatesAfterInstall = rebatesToday;
      const totalRebates = moneyFormatter.format(
        (this.state.productDiscount + this.state.installationDiscount) / 100,
      );
      const upfrontFee = moneyFormatter.format(this.state.fee / 100);
      const newPriceToday = moneyFormatter.format(
        (this.state.productPrice +
          this.state.installationPrice -
          this.state.productDiscount / 2 -
          this.state.installationDiscount / 2 +
          this.state.fee) /
          100,
      );
      const allInPrice = moneyFormatter.format(
        (this.state.productPrice +
          this.state.installationPrice -
          this.state.productDiscount -
          this.state.installationDiscount +
          this.state.fee) /
          100,
      );

      let buttons;
      if (!this.state.isIFrame) {
        buttons = (
          <div className="flex min-h-full items-center justify-center my-5">
            <button
              type="submit"
              onClick={this.onDeclineRebates}
              className="group bottom-3 flex w-1/4 justify-center rounded-md border border-[#166534] py-2 px-4 text-sm font-medium text-[#166534] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Decline
            </button>
            <br />
            <button
              type="submit"
              onClick={this.onConfirm}
              className="group bottom-3 ml-5 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Confirm
            </button>
          </div>
        );
      } else {
        if (this.state.alreadyQualified) {
          buttons = (
            <div className="bottom-3 px-16 flex flex-col w-full  items-center justify-center my-5">
              <button
                type="submit"
                onClick={this.onBackWebsite}
                className="mb-5 group bottom-3 w-full justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Back to Checkout
              </button>
              <button
                type="submit"
                onClick={this.onCancelQualification}
                className="group bottom-3 w-full justify-center rounded-md border border-[#166534] py-3 px-4 text-lg font-medium text-[#166534] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel qualification
              </button>
            </div>
          );
        } else {
          buttons = (
            <div className="bottom-3 px-16 flex flex-col w-full  items-center justify-center my-5">
              <button
                type="submit"
                onClick={this.onConfirmEcommerce}
                className="mb-5 group bottom-3 w-full justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save money with
                <img
                  src="/upfront_logo_no_bg.png"
                  className="inline-block w-20 mx-auto rounded-md object-center text-center"
                />
              </button>
              <button
                type="submit"
                onClick={this.onBackWebsite}
                className="group bottom-3 w-full justify-center rounded-md border border-[#166534] py-3 px-4 text-lg font-medium text-[#166534] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                No thanks, I'll pay full price
              </button>
            </div>
          );
        }
      }

      const obj = {
        name: string().min(1).required('Name is required'),
        phoneNumber: string().min(11, 'Invalid phone number').required('Phone number is required'),
      };
      const schema = object(obj);

      let finalInfo;
      if (this.state.accepted) {
        finalInfo = (
          <div>
            <div className="flex flex-col min-h-full items-center justify-center">
              <br />
              <p className="mt-1 text-2xl">How it works:</p>
              <div className="md:flex sm:items-center sm:justify-between">
                <ul>
                  <li className="flex">
                    <a
                      className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                        <span className="text-[#166534]">1</span>
                      </span>
                    </a>
                    <span className="self-center truncate text-sm font-medium text-gray-900">
                      Upfront advances your rebate so that you can use it today.{' '}
                    </span>
                  </li>
                  <li className="flex">
                    <a
                      className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                        <span className="text-[#166534]">2</span>
                      </span>
                    </a>
                    <span className="self-center truncate text-sm font-medium text-gray-900">
                      They guide you through the requirements for installation to ensure you get
                      your rebate.
                    </span>
                  </li>
                  <li className="flex">
                    <a
                      className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                        <span className="text-[#166534]">3</span>
                      </span>
                    </a>
                    <span className="self-center">
                      If you do not abide by the requirements, you’ll be charged for your rebate.
                    </span>
                  </li>
                </ul>
              </div>
              <p className="mt-1 text-2xl">Customer Information:</p>
              <Formik
                initialValues={{}}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  this.onSubmit(values);
                }}
              >
                {({ isSubmitting }) => (
                  <Form className="login-form large-margin-top">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                      <label
                        htmlFor="name"
                        className="block text-l font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        What is your name?
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[#166534] focus:ring-[#166534] sm:max-w-xs sm:text-sm"
                        />
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                    <br />
                    <div>
                      <Field
                        name="phoneNumber"
                        id="phoneNumber"
                        type="text"
                        className="isolate inline-flex rounded-md shadow-sm"
                      >
                        {({ field, form, meta }) => (
                          <div>
                            <PhoneInput
                              placeholder="Customer phone number"
                              country={'us'}
                              inputClass="focus:border-[#166534] focus:ring-[#166534] rounded-md"
                              onChange={(phone, country) => {
                                console.log(phone);
                                form.setFieldValue(field.name, phone);
                              }}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="phoneNumber" />
                    </div>
                    <br />
                    {this.state.formError ? <Error message={this.state.formError} /> : null}
                    <br />
                    <div className="flex min-h-full items-center justify-center">
                      <button
                        type="submit"
                        className="group bottom-3 flex w-3/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Submit
                      </button>
                      <br />
                      <br />
                    </div>
                    <br />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        );
      }

      if (this.state.productDiscount == null || this.state.loadingQualification) {
        return (
          <div>
            <Title heading={'Making the decision...'} />
            <br />
            <div className="flex min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-full space-y-8">
                <span className="group relative flex w-full justify-center py-2 px-4 text-medium font-medium">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        );
      }

      let qualifiedPrograms;
      if (false) {
        // don't show them for now
        qualifiedPrograms = (
          <div>
            <div className="flex flex-col min-h-full py-4 px-4 sm:gap-4 sm:pt-5">
              <p className="mt-1 text-2xl">Qualified Rebate programs:</p>
            </div>
            <div className="flex flex-col min-h-full px-4">
              <ul className="divide-y divide-gray-200">
                {this.state.programs.map((program, i) => {
                  return (
                    <li className="flex py-4">
                      <span className="self-center">
                        <span className="text-[#166534] text-4xl relative top-1">•</span>
                        {program}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        );
      }

      return (
        <div>
          <Title />
          {steps}
          <div className="lg:w-1/2 lg:mx-auto">
            {qualifiedPrograms}
            <p className="ml-2 mr-2 sm:ml-16 sm:mr-16 px-5 py-3 bg-[#F0F4EF] border-2 rounded border-[#2C631B]">
              <div className="flex justify-between">
                <span className="text-xl font-bold">You are pre-approved!</span>
                <span className="text-2xl text-[#2C631B] font-bold">{totalRebates}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#393A3A] text-sm">Keep more money in your wallet.</span>
                <span className="text-[#393A3A] text-sm">In rebates</span>
              </div>
            </p>
            <ul role="list" class="divide-y divide-gray-200 ml-5 mr-5 sm:ml-20 sm:mr-20">
              <li class="pt-5 pb-5">
                <div>
                  <span class="inline-block w-1/3 truncate text-lg font-bold">
                    {this.state.productName}
                  </span>
                  <span class="inline-block w-1/3 whitespace-nowrap text-lg text-gray-500 text-right">
                    x1
                  </span>
                  <span class="inline-block w-1/3 whitespace-nowrap text-lg text-gray-500 text-right">
                    {fullPrice}
                  </span>
                </div>
              </li>
              <li class="pt-5 pb-5">
                <div>
                  <span class="inline-block w-2/3 truncate text-lg text-gray-500 text-right">
                    Service fee
                  </span>
                  <span class="inline-block w-1/3 whitespace-nowrap text-lg text-gray-500 text-right">
                    {upfrontFee}
                  </span>
                </div>
                <div>
                  <span class="inline-block w-2/3 truncate text-lg text-[#166534] text-right">
                    <img
                      src="/upfront_logo_white.png"
                      className="inline-block w-20 mx-auto rounded-md object-center text-center"
                    />
                    Instant Rebate
                  </span>
                  <span class="inline-block w-1/3 whitespace-nowrap text-lg text-[#166534] text-right">
                    - {rebatesToday}
                  </span>
                </div>
              </li>
              <li class="pt-5 pb-5">
                <div>
                  <span class="inline-block w-2/3 whitespace-nowrap text-lg text-right">
                    Total at Checkout
                  </span>
                  <span class="inline-block w-1/3 whitespace-nowrap text-xl text-right font-extrabold">
                    {newPriceToday}
                  </span>
                </div>
              </li>
            </ul>
            <p className="ml-2 mr-2 sm:ml-16 sm:mr-16 px-5 py-3 bg-[#FAFAFA] border-2 rounded border-[#D9DADA]">
              <div className="flex justify-between">
                <span className="text-xl">Rebates after installation</span>
                <span className="text-xl text-[#2C631B] font-bold">{rebatesAfterInstall}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#393A3A] text-sm">
                  Once you upload all info needed for us to process your rebate
                </span>
                <span className="text-[#393A3A]"></span>
              </div>
            </p>
            <div>
              <div className="mt-5 ml-2 sm:ml-16 relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-[#166534] focus:ring-[#166534]"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="terms" className="font-medium text-[#166534]">
                    I agree to Upfront’s{' '}
                    <a
                      className="text-[#166534] underline"
                      href="https://www.knowupfront.com/terms-conditions/"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                  </label>
                </div>
              </div>
            </div>
            {finalInfo}
          </div>
          {buttons}
        </div>
      );
    } else if (currentPage == 'phone') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      const schema = object({
        phoneNumber: string().min(11, 'Invalid phone number').required('Phone number is required'),
      });

      return (
        <div>
          {steps}
          <Title heading="Sign up to Upfront" />
          <div className="flex-col min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <p className="mt-2 text-center text-sm text-gray-600">
              <div className="font-medium text-[#166534]">
                We will send a verification code to this phone number
              </div>
            </p>
            <Formik
              initialValues={{}}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                this.sendPin(values);
                resetForm();
              }}
            >
              {({ isSubmitting }) => (
                <Form className="mt-8 space-y-6">
                  <div className="flex items-center justify-center">
                    <div>
                      <Field
                        name="phoneNumber"
                        id="phoneNumber"
                        type="text"
                        className="isolate inline-flex rounded-md shadow-sm"
                      >
                        {({ field, form, meta }) => (
                          <div>
                            <PhoneInput
                              placeholder="Phone number"
                              country={'us'}
                              inputClass="focus:border-[#166534] focus:ring-[#166534] rounded-md"
                              onChange={(phone, country) => {
                                console.log(phone);
                                form.setFieldValue(field.name, phone);
                              }}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage name="phoneNumber" />
                    </div>
                  </div>
                  <div className="flex min-h-full items-center justify-center">
                    <button type="submit" className={continueButtonClass}>
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    } else if (currentPage == 'pin') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      const schema = object({
        pinNumber: string().min(6, 'Pin must be 6 digits').required('Pin is required'),
      });

      return (
        <div>
          {steps}
          <Title heading="6-digit pin" />
          <div className="flex-col min-h-full items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
            <p className="mt-2 text-center text-sm text-gray-600">
              <a href="#" className="font-medium text-[#166534]">
                Please enter the 6-digit pin we sent to your phone number. Didn't receive a pin?{' '}
                <button onClick={this.resendPin} className="text-[#166534] underline">
                  {' '}
                  Resend
                </button>
              </a>
            </p>
            <Formik
              initialValues={{}}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting }) => {
                const userId = await this.loginUser(values);

                if (userId) {
                  this.setState({ currentPage: this.getNextSuccessPage() });
                  // this.setupStripePayment();
                } else {
                  setSubmitting(false);
                  this.setState({
                    failedPinError: 'Incorrect pin. Please double check the SMS pin we sent.',
                  });
                }
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className="mt-8 space-y-6">
                  <div className="flex items-center justify-center">
                    <div>
                      <Field
                        name="pinNumber"
                        id="pinNumber"
                        type="text"
                        className="isolate inline-flex rounded-md shadow-sm"
                      >
                        {({ field, form, meta }) => (
                          <PinInput
                            length={6}
                            type="numeric"
                            style={{}}
                            inputStyle={{
                              borderColor: 'grey',
                              backgroundColor: '#f9f9f9',
                              borderRadius: '10px',
                            }}
                            onChange={(value) => {
                              console.log(value);
                              form.setFieldValue(field.name, value);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="pinNumber" />
                    </div>
                  </div>
                  {this.state.failedPinError ? <Error message={this.state.failedPinError} /> : null}
                  <div className="flex min-h-full items-center justify-center">
                    <button type="submit" className={continueButtonClass}>
                      Continue
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    } else if (currentPage == 'card') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      return (
        <div>
          {steps}
          <Title heading="Card information" />
          <div className="ml-16 mr-16 flex-col min-h-full items-center justify-center">
            <div className="flex space-around pt-10 pb-10">
              <p className="pr-2 text-center text-sm text-gray-600">
                <span className="font-medium text-[#166534]">
                  Card stored to settle your rebate later
                </span>
              </p>
              <p className="pr-2 text-center text-sm text-gray-600">
                <span className="font-medium text-[#166534]">Card is not charged today</span>
              </p>
              <p className="pr-2 text-center text-sm text-gray-600">
                <span className="font-medium text-[#166534]">
                  Proceed through checkout as normal
                </span>
              </p>
            </div>
            <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
              <section
                aria-labelledby="payment-heading"
                className="flex-auto overflow-y-auto pb-16 p-2"
              >
                <form id="payment-form" onSubmit={this.handleSubmit}>
                  <div id="payment-element">
                    {/* <!--Stripe.js injects the Payment Element--> */}
                  </div>
                  <br />
                  {this.state.stripeError ? <Error message={this.state.stripeError} /> : null}
                  <br />
                  <div className="flex min-h-full items-center justify-center">
                    <button type="submit" className={continueButtonClass}>
                      Save card
                    </button>
                  </div>
                  <div id="payment-message" className="hidden"></div>
                </form>
              </section>
            </main>
          </div>
        </div>
      );
    } else if (currentPage == 'confirmation') {
      let steps;
      if (!this.state.isIFrame) {
        steps = <Steps steps={this.getStepData()} />;
      }

      let content;
      if (this.state.accepted) {
        content = (
          <div className="text-center align-middle">
            <Title heading={'Submitted to Upfront Successfully'} />
            <div className="flex flex-col min-h-full items-center justify-center">
              <br />
              <main>
                <div>
                  <div>
                    <div className="lg:col-start-2">
                      <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Thank you for using Upfront
                      </p>
                      <p className="mt-2 text-base text-gray-500">
                        In the next 1-2 business days, they’ll reach out to you to get an account
                        set up.
                      </p>

                      <dl className="mt-16 text-sm font-medium">
                        <dt className="text-gray-900">
                          Upfront purchase number: 51547878755545848512
                        </dt>
                      </dl>

                      <dl className="mt-16 text-sm font-medium">
                        <dt className="text-lg text-gray-900">Summary:</dt>
                      </dl>
                      <br />

                      <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                        <div className="flex justify-between">
                          <dt>Original Price</dt>
                          <dd className="text-gray-900">
                            {moneyFormatter.format(
                              (this.state.productPrice + this.state.installationPrice) / 100,
                            )}
                          </dd>
                        </div>

                        <div className="flex justify-between">
                          <dt>Rebates Available</dt>
                          <dd className="text-gray-900">
                            -{' '}
                            {moneyFormatter.format(
                              (this.state.productDiscount + this.state.installationDiscount) / 100,
                            )}
                          </dd>
                        </div>

                        <div className="flex justify-between">
                          <dt>Upfront Service Fee</dt>
                          <dd className="text-gray-900">
                            + {moneyFormatter.format(this.state.fee / 100)}
                          </dd>
                        </div>

                        <div className="flex justify-between">
                          <dt>New Price</dt>
                          <dd className="text-gray-900">
                            {moneyFormatter.format(
                              (this.state.productPrice +
                                this.state.installationPrice -
                                this.state.productDiscount -
                                this.state.installationDiscount +
                                this.state.fee) /
                                100,
                            )}
                          </dd>
                        </div>
                      </dl>
                      <div className="flex min-h-full items-center justify-center">
                        <button
                          type="submit"
                          onClick={this.onDone}
                          className="group absolute bottom-3 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Start over
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        );
      } else {
        if (this.state.declineSubmitted) {
          content = (
            <div className="text-center align-middle">
              <Title heading={'Submitted'} />
              <div className="flex min-h-full items-center justify-center">
                <button
                  type="submit"
                  onClick={this.onDone}
                  className="group absolute bottom-3 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Start over
                </button>
              </div>
            </div>
          );
        } else {
          const obj = {
            feedback: string().min(1),
          };
          const schema = object(obj);

          content = (
            <div className="text-center align-middle">
              <Title heading={'You have declined the rebates'} />
              <br />
              <br />
              <div className="flex flex-col min-h-full items-center justify-center">
                <Formik
                  initialValues={{}}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }) => {
                    this.onSubmitFeedback(values);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="login-form large-margin-top">
                      <div>
                        <Field
                          name="feedback"
                          id="feedback"
                          type="text"
                          className="isolate inline-flex rounded-md shadow-sm"
                        >
                          {({ field, form, meta }) => (
                            <div>
                              <label
                                for="comment"
                                class="block text-sm font-medium leading-6 text-gray-900"
                              >
                                If possible, we'd love to hear more about why you didn't take up our
                                offer. Add your comment below:
                              </label>
                              <div class="mt-2">
                                <textarea
                                  onChange={(feedback) => {
                                    console.log(feedback);
                                    form.setFieldValue(field.name, feedback.target.value);
                                  }}
                                  rows="4"
                                  name="feedback"
                                  id="feedback"
                                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                      <br />
                      <div className="flex min-h-full items-center justify-center">
                        <button
                          type="submit"
                          className="group bottom-3 flex w-1/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          );
        }
      }

      return (
        <div>
          {steps}
          {content}
        </div>
      );
    }
  }
}

export default (props) => <Qualification {...props} searchParams={useSearchParams()} />;
