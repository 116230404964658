import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Info from './shared/Info';
import Qualification from './DEPRECATED_discount/Qualification';
import Loan from './upfront-pay/Loan.js';
import Dashboard from './companion/Dashboard.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

if (!window.localstorage) {
  const storageMock = {
    _data: {},
    setItem: function (id, val) {
      return (this._data[id] = val);
    },
    getItem: function (id) {
      return this._data[id];
    },
    removeItem: function (id) {
      return delete this._data[id];
    },
    clear: function () {
      return (this._data = {});
    },
  };

  Object.defineProperty(window, 'localStorage', {
    value: storageMock,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="wrapper">
    <BrowserRouter>
      <Routes>
        <Route path="/info" element={<Info />} />
        <Route path="/qualification" element={<Qualification />} />
        <Route path="/upfront-pay" element={<Loan />} />
        <Route path="/companion" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  </div>,
);
