export default function Title(props) {
  return (
    <div>
      <div className="flex min-h-full items-center justify-center pt-4 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="bg-white">
            <div className="md:flex md:items-center md:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="text-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  <img
                    src="/upfront_logo_white.png"
                    className="inline-block w-36 mx-auto rounded-md object-center text-center"
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="text-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {props.heading}
          </h2>
        </div>
      </div>
    </div>
  );
}
