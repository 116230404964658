import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { fetchStripePaymentToken } from './network';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ onSuccess }) => {
  const [clientSecret, setClientSecret] = useState('');

  const fetchStripe = async () => {
    const stripeResponse = await fetchStripePaymentToken();
    console.log(stripeResponse);
    setClientSecret(stripeResponse.clientSecret);
  };

  const onComplete = () => {
    console.log('stripe complete');
    onSuccess();
  };

  useEffect(() => {
    fetchStripe();
  }, []);

  return (
    <div id="checkout h-[100%]">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret, onComplete }}>
          <EmbeddedCheckout className="h-[100%]" />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default CheckoutForm;
