// Based on https://tailwindui.com/components/application-ui/navigation/steps
import { FaCheck } from 'react-icons/fa';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function StepsCircles({ steps }) {
  return (
    <div className="relative w-full max-w-xl mx-auto mb-6">
      <ol role="list" className="flex justify-between">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20 flex-grow' : '',
              'relative',
            )}
          >
            {step.status === 'completed' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-green-800" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-green-800">
                  <FaCheck className="w-4 h-4 text-white" />
                  <span className="absolute top-full mt-2 w-32 text-center left-1/2 transform -translate-x-1/2 text-xs text-gray-400 font-bold">
                    {step.name}
                  </span>
                </div>
              </>
            ) : step.status === 'active' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex h-8 w-8 items-center justify-center rounded-full bg-green-800"
                  aria-current="step"
                >
                  <span className="h-4 w-4 rounded-full bg-white" aria-hidden="true" />
                  <span className="absolute top-full mt-2 w-32 text-center left-1/2 transform -translate-x-1/2 text-xs text-gray-400 font-bold">
                    {step.name}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-gray-200" />
                </div>
                <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                  <span className="h-2.5 w-2.5 rounded-full bg-transparent" aria-hidden="true" />
                  <span className="absolute top-full mt-2 w-32 text-center left-1/2 transform -translate-x-1/2 text-xs text-gray-400 font-bold">
                    {step.name}
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}
