import Title from './Title';

export default function Info(props) {
  return (
    <div>
      <Title heading="Receive instant rebates with Upfront" />
      <div className="flex min-h-full items-center justify-center">
        <div className="md:flex md:items-center md:justify-between">
          <ul className="divide-y divide-gray-200">
            <li className="flex py-4">
              <a
                className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                  <span className="text-[#166534]">1</span>
                </span>
              </a>
              <span className="self-center">Sign up for Upfront with just your phone number</span>
            </li>
            <li className="flex py-4">
              <a
                className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                  <span className="text-[#166534]">2</span>
                </span>
              </a>
              <span className="self-center">Qualify for instant rebates on your purchase</span>
            </li>
            <li className="flex py-4">
              <a
                className="flex ml-3 flex-col items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-[#166534]">
                  <span className="text-[#166534]">3</span>
                </span>
              </a>
              <span className="self-center">
                Checkout and we'll be in touch with any extra information we need from you to
                process your rebates
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center">
        <button
          type="submit"
          onClick={() => {
            window.parent.postMessage(
              {
                message: 'close modal',
              },
              '*',
            );
          }}
          className="group absolute bottom-3 flex w-3/4 justify-center rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Back to Website
        </button>
      </div>
    </div>
  );
}
