import React from 'react';
import { BiRightArrow } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa'; // <-- Import the FaTimes icon
import StepsCircles from './Components/StepsCircles';

const DeclinedZip = () => {
  const STEPS = [
    { name: '1. Your Rebates', status: 'active' },
    { name: '2. Apply', status: '' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  const onContinueMerchant = async () => {
    //To: Implement Logic
    console.log('back to merchant!');

    window.parent.postMessage(
      {
        message: 'upfront pay exit',
      },
      '*',
    );
  };

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      <StepsCircles steps={STEPS} />
      <br></br>
      <br></br>{' '}
      <div className="max-w-xl mx-auto text-center">
        <h1 className="text-4xl font-bold mb-4 flex items-center justify-center">
          <div className="w-10 h-10 rounded-full bg-white border-2 border-red-600 flex items-center justify-center text-red-600 mr-2">
            {' '}
            <FaTimes className="w-5 h-5 text-red-600" />{' '}
          </div>
          You don't qualify for Upfront Pay
        </h1>
        <p className="text-gray-700">We're sorry, but we are not yet available in your state.</p>
        <button
          onClick={onContinueMerchant}
          type="submit"
          className="group mt-3 flex items-center justify-center w-full rounded-md border border-transparent bg-[#166534] py-2 px-4 text-sm font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Take me back to merchant
          <BiRightArrow className="ml-2 text-white" />
        </button>
      </div>
    </div>
  );
};

export default DeclinedZip;
