export async function fetchCompanionConsumer({ uuid, email }) {
  console.log(uuid, email);
  let getConsumerResponse;
  if (uuid) {
    getConsumerResponse = await fetch(
      `https://api.knowupfront.com/private_get_companion_consumer?uuid=${uuid}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  } else {
    getConsumerResponse = await fetch(
      `https://api.knowupfront.com/private_get_companion_consumer?email=${email}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }
  return await getConsumerResponse.json();
}

export async function fetchRebatesForEquipment(
  zipCode,
  merchantId,
  equipmentModelCart,
  productCart,
) {
  const getRebatesResponse = await fetch(
    'https://api.knowupfront.com/private_rebates_for_equipment_model_cart',
    {
      method: 'POST',
      body: JSON.stringify({
        zip_code: zipCode,
        equipment_model_cart: equipmentModelCart,
        product_cart: productCart,
        merchant_id: merchantId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await getRebatesResponse.json();
}

export async function submitComingSoon(consumerId, submissionData) {
  const resp = await fetch('https://api.knowupfront.com/private_companion_coming_soon_submit', {
    method: 'POST',
    body: JSON.stringify({
      companion_consumer_id: consumerId,
      data: submissionData,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  return await resp.json();
}

export async function submitPaidForRebatesDeposit(consumerId) {
  const resp = await fetch(
    'https://api.knowupfront.com/private_companion_paid_for_rebates_deposit',
    {
      method: 'POST',
      body: JSON.stringify({
        companion_consumer_id: consumerId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await resp.json();
}

export async function submitInstallationTypeformSubmitted(consumerId) {
  const resp = await fetch(
    'https://api.knowupfront.com/private_companion_installation_typeform_submitted',
    {
      method: 'POST',
      body: JSON.stringify({
        companion_consumer_id: consumerId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await resp.json();
}

export async function submitInstallationAvailableWindows(availableWindows, consumerId) {
  const resp = await fetch(
    'https://api.knowupfront.com/private_companion_installation_available_windows',
    {
      method: 'POST',
      body: JSON.stringify({
        available_windows: availableWindows,
        companion_consumer_id: consumerId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await resp.json();
}

export async function fetchStripePaymentToken(email) {
  const getTokenResponse = await fetch(
    `https://api.knowupfront.com/private_get_stripe_payment_token`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await getTokenResponse.json();
}

export async function logFrontendMetric(
  eventName,
  consumerId,
  sessionId,
  merchantId,
  source,
  email,
) {
  const getRebatesResponse = await fetch(
    'https://api.knowupfront.com/private_frontend_log_metric',
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          consumer_id: consumerId,
          source: source,
          email: email,
        },
        merchant_id: merchantId,
        event_name: eventName,
        session_id: sessionId,
      }),
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return await getRebatesResponse.json();
}
