import React from 'react';
import { BiLeftArrow } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';

export const PreviousButton = ({ isLoading, text, onClick }) => {
  const buttonText = text || 'Previous';
  return (
    <button
      onClick={onClick ? () => onClick() : null}
      type="submit"
      className="group flex items-center justify-center w-full rounded-md border border-transparent text-green-800 py-2 px-4 text-sm font-medium hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      disabled={isLoading}
    >
      <BiLeftArrow className="mr-2 text-green-800" />
      {buttonText}
    </button>
  );
};
