import React, { useState } from 'react';

const Modal = ({ isOpen, onClose, children, isGreen }) => {
  if (!isOpen) return null;

  // Prevent click inside the modal from closing it
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  const handleCloseButtonClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const modalColor = isGreen ? 'bg-[#f1faf1]' : 'bg-white';

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className={`${modalColor} relative rounded-lg p-8 w-[90%] h-[80%] max-h-[80%] md:w-[800px] md:h-[800px] md:max-h-[800px] overflow-y-auto`}
        onClick={handleModalContentClick}
      >
        {children}
        <div onClick={handleCloseButtonClick} class="absolute top-0 right-0 pt-2 pr-2">
          <button class="text-black hover:text-gray-700">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
