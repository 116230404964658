import React from 'react';
import { BiRightArrow } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';

export const ContinueButton = ({ isLoading, text, onClick }) => {
  const buttonText = text || 'Continue';
  return (
    <button
      onClick={onClick ? () => onClick() : null}
      type="submit"
      className="group flex items-center justify-center w-full rounded-md border border-transparent bg-green-800 py-2 px-4 text-sm font-medium text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      disabled={isLoading}
    >
      {isLoading ? (
        <FaSpinner className="animate-spin inline h-5" />
      ) : (
        <>
          {buttonText}
          <BiRightArrow className="ml-2 text-white" />
        </>
      )}
    </button>
  );
};
