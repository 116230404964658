import React, { useEffect, useState } from 'react';
import { PaymentBar } from './Components/PaymentBar';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { Loading } from './Components/Loading';
import { PreviousButton } from './Components/PreviousButton';

const NonInstantRebate = ({
  supabase,
  applicationId,
  acceptedOfferId,
  onComplete,
  applicationResponse,
  applicantInfo,
  onBack,
  testMode,
  prevMode,
  env,
}) => {
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [agreementReady, setAgreementReady] = useState(false); // Track if the document is ready

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'completed' },
    { name: '3. Approval', status: 'active' },
    { name: '4. Finish Up', status: '' },
  ];

  const {
    apr,
    financed_amount,
    monthly_payment,
    number_of_payments,
    rebate_amount_cents,
    total_interest_cents,
    first_payment_date,
  } = applicationResponse;

  const total_interest_dollars = total_interest_cents / 100;
  const rebateAmount = rebate_amount_cents / 100;

  const monthlyPaymentDollars = monthly_payment / 100;
  const totalAmountAfterInterest = financed_amount / 100 + total_interest_dollars;
  const purchaseAmountDollars = financed_amount / 100;
  let totalAmountAfterRebates = totalAmountAfterInterest - rebateAmount;
  if (totalAmountAfterRebates < 0) {
    totalAmountAfterRebates = 0;
  }
  applicantInfo.totalInstallments = monthlyPaymentDollars.toFixed(2);
  applicantInfo.first_payment_date = first_payment_date;

  const [AgreementResponse, setAgreementResponse] = useState(null);
  const onContinueApproved = async () => {
    setIsLoading(true);
    if (agreementReady) {
      onComplete(AgreementResponse, applicantInfo);
    }
  };

  useEffect(() => {
    const loadPDF = async () => {
      try {
        console.log('loading pdf...');
        const agreementResponse = await supabase.functions.invoke('private_new_loan_agreement', {
          body: {
            applicationId: applicationId,
            acceptedOfferId: acceptedOfferId,
            env: env,
          },
        });

        console.log('agreementResponse', agreementResponse);
        if (agreementResponse.error) {
          // TODO: handle the error in the UI
          console.error('error with pdf call', agreementResponse.error);
        } else {
          console.log('pdf data', agreementResponse.data.document_url);
          if (agreementResponse.data.document_url) {
            setAgreementResponse(agreementResponse.data);
            setAgreementReady(true); // Document is ready
          }
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      }
    };

    if (!testMode && !prevMode) {
      loadPDF();
    } else {
      // setAgreementResponse({
      //   document_url:
      //     'https://assets.speakcdn.com/assets/2703/bulletin-may26.pdf',
      // });
      setAgreementReady(true); // Document is ready
    }
    setIsPreLoading(false);
  }, []);

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4">
      <StepsCircles steps={STEPS} />

      {isPreLoading ? (
        <Loading text="Getting Application Result..." />
      ) : !agreementReady ? ( // Display loading until document is ready
        <Loading text="Loading..." />
      ) : (
        <>
          <br></br>
          <div className="max-w-xl mx-auto h-[calc(100vh-200px)] overflow-y-auto">
            <div className="flex flex-col w-full">
              <h1 className="text-black text-4xl text-left font-bold mb-4">You're Approved!</h1>
              <p className="text-black text-xl text-left mb-4">
                <span className="font-bold">${monthlyPaymentDollars.toFixed(2)} / month</span> for{' '}
                {number_of_payments} months
              </p>
            </div>

            <div className="max-w-xl mx-auto mb-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Purchase Amount</span>
                <span className="text-gray-700">${purchaseAmountDollars.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Interest ({apr}% APR)</span>
                <span className="text-gray-700">${total_interest_dollars.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Total Installments</span>
                <span className="text-black font-bold">${totalAmountAfterInterest.toFixed(2)}</span>
              </div>
            </div>

            <div className="bg-gray-100 border-2 border-green-500 p-4 rounded-md mb-4 h-25">
              <p className="text-black text-xl mb-2 font-bold">
                You could pay as little as ${totalAmountAfterRebates.toFixed(2)} total after
                rebates!
              </p>
              <p className="text-gray-500 text-base mb-4">
                We handle the paperwork to save you money.
              </p>
              <div className="flex justify-between items-center">
                <p className="text-black text-sm font-bold">Rebates expected</p>
                <p className="text-green-500 text-3xl ml-4 font-bold">${rebateAmount}</p>
              </div>
            </div>

            <p className="font-bold text-lg">Payment Schedule</p>
            <br></br>
            <p className="font-semibold">12 total payments</p>

            <br></br>
            <PaymentBar
              firstPaymentDate={first_payment_date}
              monthlyPayment={monthlyPaymentDollars}
              numberOfPayments={number_of_payments}
            />

            <br></br>
            <br></br>
          </div>

          <div className="button-container fixed bottom-0 left-0 w-full z-10">
            <ContinueButton
              onClick={onContinueApproved}
              text="Accept installment offer"
              isLoading={isLoading}
            />
            <PreviousButton isLoading={isLoading} onClick={onBack} />
          </div>
        </>
      )}
    </div>
  );
};

export default NonInstantRebate;
