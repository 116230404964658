import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import ZipCode from './steps/ZipCode.js';
import Qualification from './steps/Qualification.js';
import PhoneNumber from './steps/PhoneNumber.js';
import Pin from './steps/Pin.js';
import PersonalInfo from './steps/PersonalInfo.js';
import SignLoan from './steps/SignLoan.js';
import AutoPay from './steps/AutoPay.js';
import Complete from './steps/Complete.js';
import DeclinedLoan from './steps/DeclinedLoan.js';
import DeclinedZip from './steps/DeclinedZip.js';
import NonInstantRebate from './steps/NonInstantRebate.js';
import InstantRebate from './steps/InstantRebate.js';
import { Loading } from './steps/Components/Loading.js';

const Loan = () => {
  // Extract path parameters from the URL
  // NEED TO PASS THESE PARAMS LIKE: http://localhost:3000/upfront-pay?merchantProductId=8326356861242&merchantId=1&productPrice=69999
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get('merchantId'); // non-null always
  const checkoutId = searchParams.get('checkoutId'); // non-null always
  const env = searchParams.get('env') || 'production';
  const testMode = searchParams.get('testMode') || false; // testMode to avoid backend calls, only testing frontend

  const [supabase] = useState(
    createClient(
      'https://uvslaluyawuujqysrzuj.supabase.co',
      process.env.REACT_APP_SUPABASE_AUTH_KEY,
    ),
  );

  // State machine
  const [currentPage, setCurrentPage] = useState('initLoading');
  const [flow] = useState({
    initLoading: {
      nextSuccess: 'zipCode',
    },
    zipCode: {
      nextSuccess: 'qualification',
      nextFailure: 'declinedRebates',
    },
    qualification: {
      nextSuccess: 'phoneNumber',
      nextFailure: 'declinedRebates',
      back: 'zipCode',
    },
    phoneNumber: {
      nextSuccess: 'pin',
      back: 'qualification',
      backSkipped: 'zipCode',
    },
    approved: {
      nextSuccess: 'sign',
      back: 'personalInfo',
    },
    pin: {
      nextSuccess: 'personalInfo',
      back: 'phoneNumber',
    },
    personalInfo: {
      nextSuccess: 'approved',
      nextFailure: 'declinedLoan',
    },

    sign: {
      nextSuccess: 'autopay',
      back: 'approved',
    },
    autopay: {
      nextSuccess: 'complete',
    },
    complete: {
      nextSuccess: null,
      back: 'autopay',
    },
  });

  // Extracted data from checkout cart info
  const [merchantProductId, setMerchantProductId] = useState(null);
  const [productPrice, setProductPrice] = useState(0);
  // We don't use this, but leaving it for future, since backend uses it for calculations
  const [installationPrice, setInstallationPrice] = useState(0);

  // Shared state between Upfront Pay steps
  const [prevState, setPrevState] = useState(null);
  const [productDiscount, setProductDiscount] = useState(null);
  const [installationDiscount, setInstallationDiscount] = useState(null);
  const [instantProductDiscount, setInstantProductDiscount] = useState(null);
  const [instantInstallationDiscount, setInstantInstallationDiscount] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [loanApplicationResponse, setLoanApplicationResponse] = useState(null);
  const [loanAgreementResponse, setLoanAgreementResponse] = useState(null);
  const [qualificationQuestions, setQualificationQuestions] = useState([]);
  const [eligiblePrograms, setEligiblePrograms] = useState([]);
  const [zipCode, setZipCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [totalRebateAmount, setTotalRebateAmount] = useState(null);
  const [borrowerId, setBorrowerId] = useState(null);
  const [loanId, setLoanId] = useState(null);
  const [noRebateBool, setNoRebateBool] = useState(false);
  const [applicantInfo, setapplicantInfo] = useState(null);
  const [qualificationData, setQualificationData] = useState({});
  const [neuroUserId, setNeuroUserId] = useState(null);
  const [neuroIdSiteId, setNeuroIdSiteId] = useState(null);
  const [cart, setCart] = useState(null);

  useEffect(() => {
    if (!merchantId || !checkoutId) {
      console.error('merchantId and checkoutId are required.');
      return;
    }

    const fetchCartData = async () => {
      const cartResp = await supabase.functions.invoke(
        `private_get_cart_info?merchantId=${merchantId}&checkoutId=${checkoutId}`,
        {
          method: 'GET',
        },
      );
      setCart(cartResp.data);
      setMerchantProductId(cartResp.data.primary_product_id);
      setProductPrice(cartResp.data.cart_json.total);
      setCurrentPage(flow[currentPage]?.nextSuccess);
    };

    fetchCartData();
  }, []);

  const onZipComplete = (qualificationQuestions, eligiblePrograms, zipCode) => {
    setQualificationQuestions(qualificationQuestions);
    setEligiblePrograms(eligiblePrograms);
    setZipCode(zipCode);

    //skip qual questions page
    if (qualificationQuestions.length === 0 && eligiblePrograms.length > 0) {
      setCurrentPage('phoneNumber');
    }
    //skip qual questions page and set reabtes to 0
    else if (qualificationQuestions.length === 0 && eligiblePrograms.length === 0) {
      setNoRebateBool(true);
      setCurrentPage('phoneNumber');
    } else {
      setCurrentPage(flow[currentPage]?.nextSuccess);
    }
  };

  const onZipFailure = () => {
    setCurrentPage('declinedZip');
  };

  const onQualificationComplete = (qualificationData) => {
    setQualificationData(qualificationData);
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onQualificationBack = () => {
    setCurrentPage(flow[currentPage]?.back);
  };

  const onPhoneBack = () => {
    //if qualification question page was skipped
    if (qualificationQuestions.length === 0) {
      setCurrentPage(flow[currentPage]?.backSkipped);
    } else {
      setCurrentPage(flow[currentPage]?.back);
    }
  };

  const onPinBack = () => {
    setCurrentPage(flow[currentPage]?.back);
  };

  const onPhoneComplete = (phoneNumber, total, programData) => {
    setPhoneNumber(phoneNumber);
    setTotalRebateAmount(total);
    setProductDiscount(programData.productDiscount);
    setInstallationDiscount(programData.installationDiscount);
    setPrograms(programData.programs);
    setInstantProductDiscount(programData.instantProductDiscount);
    setInstantInstallationDiscount(programData.instantInstallationDiscount);
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onPinComplete = () => {
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onPersonalInfoComplete = (loanApplicationResponse, applicantInfo) => {
    setLoanApplicationResponse(loanApplicationResponse);
    setapplicantInfo(applicantInfo);
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onPersonalInfoFailure = (loanApplicationResponse) => {
    setLoanApplicationResponse(loanApplicationResponse);
    setCurrentPage(flow[currentPage]?.nextFailure);
  };
  const onApprovedComplete = (loanAgreement, applicantInfo) => {
    setapplicantInfo(applicantInfo);
    if (!loanAgreementResponse) {
      setLoanAgreementResponse(loanAgreement);
    }

    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onApprovedBack = () => {
    setCurrentPage(flow[currentPage]?.back);
  };

  const onSignLoanComplete = (loanId, borrowerId) => {
    setLoanId(loanId);
    setBorrowerId(borrowerId);
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };
  const onSignBack = () => {
    setPrevState(true);
    setCurrentPage(flow[currentPage]?.back);
  };

  const onAutopayComplete = () => {
    setCurrentPage(flow[currentPage]?.nextSuccess);
  };

  const onCompleteBack = () => {
    setCurrentPage(flow[currentPage]?.back);
  };

  switch (currentPage) {
    case 'initLoading':
      return <Loading />;
    case 'zipCode':
      return (
        <ZipCode
          supabase={supabase}
          merchantProductId={merchantProductId}
          merchantId={merchantId}
          onComplete={onZipComplete}
          onFailure={onZipFailure}
          setNeuroUserId={setNeuroUserId}
          setNeuroIdSiteId={setNeuroIdSiteId}
          env={env}
        />
      );
    case 'qualification':
      return (
        <Qualification
          supabase={supabase}
          qualificationQuestions={qualificationQuestions}
          onComplete={onQualificationComplete}
          onBack={onQualificationBack}
        />
      );
    case 'phoneNumber':
      return (
        <PhoneNumber
          supabase={supabase}
          qualificationData={qualificationData}
          installationPrice={installationPrice}
          merchantId={merchantId}
          productPrice={productPrice}
          eligiblePrograms={eligiblePrograms}
          onComplete={onPhoneComplete}
          onBack={onPhoneBack}
          noRebateBool={noRebateBool}
          testMode={testMode}
          env={env}
        />
      );
    case 'pin':
      return (
        <Pin
          supabase={supabase}
          phoneNumber={phoneNumber}
          onComplete={onPinComplete}
          onBack={onPinBack}
          testMode={testMode}
          env={env}
        />
      );
    case 'personalInfo':
      return (
        <PersonalInfo
          supabase={supabase}
          phoneNumber={phoneNumber}
          onComplete={onPersonalInfoComplete}
          onFailure={onPersonalInfoFailure}
          rebateAmount={productDiscount + installationDiscount}
          instantRebateAmount={instantProductDiscount + instantInstallationDiscount}
          purchaseAmount={productPrice + installationPrice}
          totalRebateAmount={totalRebateAmount}
          neuroUserId={neuroUserId}
          neuroIdSiteId={neuroIdSiteId}
          testMode={testMode}
          merchantId={merchantId}
          env={env}
        />
      );
    case 'approved':
      console.log('approved!');
      // Render different pages based on the whichPage state variable
      if (loanApplicationResponse.instant_rebate_amount_cents > 0) {
        // setWhichPage('instant');
        return (
          <InstantRebate
            supabase={supabase}
            applicationId={loanApplicationResponse.pier_application_id}
            acceptedOfferId={loanApplicationResponse.pier_offer_id}
            onComplete={onApprovedComplete}
            applicationResponse={loanApplicationResponse}
            applicantInfo={applicantInfo}
            onBack={onApprovedBack}
            testMode={testMode}
            prevMode={prevState}
            env={env}
          />
        );
      } else {
        // setWhichPage('non-instant');
        return (
          <NonInstantRebate
            supabase={supabase}
            applicationId={loanApplicationResponse.pier_application_id}
            acceptedOfferId={loanApplicationResponse.pier_offer_id}
            onComplete={onApprovedComplete}
            applicationResponse={loanApplicationResponse}
            applicantInfo={applicantInfo}
            onBack={onApprovedBack}
            testMode={testMode}
            prevMode={prevState}
            env={env}
          />
        );
      }

    case 'sign':
      console.log(loanAgreementResponse);
      return (
        <SignLoan
          supabase={supabase}
          loanPdfURL={loanAgreementResponse.document_url}
          loanAgreementResponse={loanAgreementResponse}
          onComplete={onSignLoanComplete}
          onBack={onSignBack}
          testMode={testMode}
          eligiblePrograms={eligiblePrograms}
          programs={programs}
          merchantId={merchantId}
          installationPrice={installationPrice}
          productPrice={productPrice}
          env={env}
        />
      );
    case 'autopay':
      return (
        <AutoPay
          supabase={supabase}
          borrowerId={borrowerId}
          loanId={loanId}
          onComplete={onAutopayComplete}
          applicantInfo={applicantInfo}
          testMode={testMode}
          env={env}
        />
      );
    case 'complete':
      return <Complete onBack={onCompleteBack} />;
    case 'declinedZip':
      window.parent.postMessage(
        {
          message: 'upfront pay error',
          error: 'declinedZip',
        },
        '*',
      );

      return <DeclinedZip />;
    case 'declinedLoan':
      window.parent.postMessage(
        {
          message: 'upfront pay error',
          error: 'declinedLoan',
        },
        '*',
      );
      return <DeclinedLoan loanApplicationResponse={loanApplicationResponse} />;
    default:
      return null;
  }
};
export default Loan;
