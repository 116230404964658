import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { extractZipCode, getOrCreateSession } from './helpers.js';
import {
  fetchCompanionConsumer,
  fetchRebatesForEquipment,
  submitComingSoon,
  submitPaidForRebatesDeposit,
  logFrontendMetric,
  submitInstallationAvailableWindows,
} from './network.js';
import Modal from './Modal';
import StripeCheckout from './StripeCheckout.js';

const sessionId = getOrCreateSession();

const threeDaysFromToday = new Date();
threeDaysFromToday.setDate(threeDaysFromToday.getDate() + 3);
// Check if the date is Saturday (6) or Sunday (0) and adjust accordingly
while (threeDaysFromToday.getDay() === 0 || threeDaysFromToday.getDay() === 6) {
  threeDaysFromToday.setDate(threeDaysFromToday.getDate() + 1); // Move to the next day
}

const twoWeeksFromToday = new Date();
twoWeeksFromToday.setDate(twoWeeksFromToday.getDate() + 17);

function formatDateString(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day} ${year}`;
}

function convertBackToSelectedBlocks(availableBlocks) {
  const selectedBlocks = {};

  availableBlocks.forEach((blockObj) => {
    for (const [key, value] of Object.entries(blockObj)) {
      // Initialize the array if the key doesn't exist
      if (!selectedBlocks[key]) {
        selectedBlocks[key] = [];
      }

      // Concatenate the blocks to the corresponding key
      selectedBlocks[key] = selectedBlocks[key].concat(value);
    }
  });

  return selectedBlocks;
}

const Dashboard = () => {
  let [iframeUrl, setIframeUrl] = useState('');
  let [purchaseDate, setPurchaseDate] = useState('');
  let [merchantImage, setMerchantImage] = useState('');
  let [firstName, setFirstName] = useState('');
  let [productNames, setProductNames] = useState([]);
  let [consumerId, setConsumerId] = useState('');
  const [isRebatesModalOpen, setIsRebatesModalOpen] = useState(false);
  const [selections, setSelections] = useState({
    carbon_credits: false,
    financing: false,
    warranty: false,
    energySavings: false,
  });
  const [isComingSoonSubmitted, setIsComingSoonSubmitted] = useState(false);
  const [dynamicContentHeight, setDynamicContentHeight] = useState(750);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [loginEmail, setLoginEmail] = useState(null);
  const [isRebatesPaid, setIsRebatesPaid] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [hideRebatesModule, setHideRebatesModule] = useState(false);
  const [hideRebatesCTA, setHideRebatesCTA] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [flowSteps, setFlowSteps] = useState([]);
  const navigate = useNavigate();
  const [calendarValue, setCalendarValue] = useState(threeDaysFromToday);
  const [selectedBlocks, setSelectedBlocks] = useState({
    [threeDaysFromToday]: [
      {
        id: 1,
        text: '8:00AM - 1:00PM',
        selected: false,
      },
      {
        id: 2,
        text: '1:00PM - 6:00PM',
        selected: false,
      },
    ],
  });
  const [showSubmittedDates, setShowSubmittedDates] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {}, []);

  const onCalendarChange = (selectedDate) => {
    const copy = Object.assign({}, selectedBlocks);
    console.log(copy);
    if (!copy[selectedDate]) {
      console.log('setting values');
      copy[selectedDate] = [
        {
          id: 1,
          text: '8:00AM - 1:00PM',
          selected: false,
        },
        {
          id: 2,
          text: '1:00PM - 6:00PM',
          selected: false,
        },
      ];
    }
    setSelectedBlocks(copy);
    setCalendarValue(selectedDate);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelections({
      ...selections,
      [name]: checked,
    });
  };

  const handleComingSoonSubmit = (event) => {
    logFrontendMetric('companion_dashboard_submit_survey', consumerId, sessionId, merchantId);
    event.preventDefault();
    console.log(selections);
    submitComingSoon(consumerId, selections);
    setIsComingSoonSubmitted(true);
  };

  const getFlowSteps = (rebatesPaid, scheduledInstall, hasRebates) => {
    if (hasRebates) {
      if (!scheduledInstall && !rebatesPaid) {
        return [
          {
            isActive: false,
            name: 'Order equipment',
            id: 'order_equipment',
            status: 'Complete',
          },
          {
            isActive: true,
            name: 'Schedule Install',
            id: 'schedule_install',
            status: 'In Progress',
          },
          {
            isActive: false,
            name: 'Your Rebates',
            id: 'get_rebates',
            status: 'After Install',
          },
          {
            isActive: false,
            name: 'Other Savings',
            id: 'carbon_credits',
            status: 'After Install',
          },
        ];
      } else if (scheduledInstall && !rebatesPaid) {
        return [
          {
            isActive: false,
            name: 'Order equipment',
            id: 'order_equipment',
            status: 'Complete',
          },
          {
            isActive: false,
            name: 'Schedule Install',
            id: 'schedule_install',
            status: 'Scheduled',
          },
          {
            isActive: true,
            name: 'Your Rebates',
            id: 'get_rebates',
            status: 'Current',
          },
          {
            isActive: false,
            name: 'Other savings',
            id: 'carbon_credits',
            status: 'After Install',
          },
        ];
      } else if (scheduledInstall && rebatesPaid) {
        return [
          {
            isActive: false,
            name: 'Order Equipment',
            id: 'order_equipment',
            status: 'Complete',
          },
          {
            isActive: false,
            name: 'Schedule Install',
            id: 'schedule_install',
            status: 'Scheduled',
          },
          {
            isActive: true,
            name: 'Your Rebates',
            id: 'get_rebates',
            status: 'In Progress',
          },
          {
            isActive: false,
            name: 'Other savings',
            id: 'carbon_credits',
            status: 'Next',
          },
        ];
      }
    } else {
      if (!scheduledInstall) {
        return [
          {
            isActive: false,
            name: 'Order Equipment',
            id: 'order_equipment',
            status: 'Complete',
          },
          {
            isActive: true,
            name: 'Schedule Install',
            id: 'schedule_install',
            status: 'In Progress',
          },
          {
            isActive: false,
            name: 'Other Savings',
            id: 'carbon_credits',
            status: 'After Install',
          },
        ];
      } else if (scheduledInstall) {
        return [
          {
            isActive: false,
            name: 'Order Equipment',
            id: 'order_equipment',
            status: 'Complete',
          },
          {
            isActive: false,
            name: 'Schedule Install',
            id: 'schedule_install',
            status: 'Scheduled',
          },
          {
            isActive: true,
            name: 'Other Savings',
            id: 'carbon_credits',
            status: 'Current',
          },
        ];
      }
    }
  };

  const fetchData = async ({ uuid, email, source }) => {
    const consumer = await fetchCompanionConsumer({ uuid, email });
    console.log(consumer);
    if (consumer.error) {
      logFrontendMetric('companion_dashboard_prompt_login', null, sessionId, null, source);
      setShowLoginPage(true);
      return;
    }
    setShowLoginPage(false);

    const searchParams = new URLSearchParams();
    searchParams.set('u', consumer.uuid);
    navigate(`?${searchParams.toString()}`, { replace: true });

    setPurchaseDate(new Date(consumer.created_at).toLocaleDateString());
    setFirstName(consumer.first_name);
    setLoginEmail(consumer.email_address);
    setConsumerId(consumer.id);
    setIsRebatesPaid(consumer.paid_for_rebates_deposit);
    if (consumer.installation_available_windows) {
      setSelectedBlocks(convertBackToSelectedBlocks(consumer.installation_available_windows));
      setShowSubmittedDates(true);
    }

    const zipCode = extractZipCode(consumer.shipping_address_line_2);
    setZipCode(zipCode);

    let products;
    let quantities;
    let equipmentModelCart;
    let productCart;
    if (consumer.products) {
      // from webhook
      productCart = consumer.products;
    } else {
      // from email / klaviyo parsing
      products = consumer.skus.split(',');
      quantities = consumer.quantity_skus.split(',');
      equipmentModelCart = products.map((product, i) => ({
        model: product,
        quantity: quantities[i],
      }));
    }

    const merchantId = consumer.merchant_id;
    setMerchantId(merchantId);

    const rebates = await fetchRebatesForEquipment(
      zipCode,
      merchantId,
      equipmentModelCart,
      productCart,
    );

    setMerchantImage(rebates.merchantImage);

    // we'll zoom in and show rebates on the product with the highest amount of rebates
    // this will be the best optimistic estimate of rebates for a cx
    let highestRebateProduct;
    let highestRebateAmount = 0;
    let highestRebateProductId;
    let highestRebateMerchantProductId;
    const productNames = [];
    for (const product in rebates.equipmentModelToRebatesMap) {
      productNames.push({
        name: rebates.equipmentModelToRebatesMap[product].productName,
        type: rebates.equipmentModelToRebatesMap[product].equipment.equipment_type_id,
      });
      if (rebates.equipmentModelToRebatesMap[product].minRebatesTotal > highestRebateAmount) {
        highestRebateProduct = product;
        ({
          productId: highestRebateProductId,
          minRebatesTotal: highestRebateAmount,
          merchantProductId: highestRebateMerchantProductId,
        } = rebates.equipmentModelToRebatesMap[product]);
      }
    }
    setProductNames(productNames);

    let productPrice = 1000000;

    if (highestRebateAmount === 0) {
      // hide the rebates module
      setHideRebatesModule(true);
    } else {
      // only show CTA if the highest rebate product has some application_type = 'open' OR application_type = 'semi-open' rebates
      const openRebates = rebates.equipmentModelToRebatesMap[highestRebateProduct].programs.filter(
        (program) =>
          program.application_type === 'open' ||
          program.application_type === 'semi-open' ||
          program.application_type === 'by_request' ||
          program.application_type === 'semi-closed',
      );
      if (openRebates.length > 0) {
        setHideRebatesCTA(false);
      }

      if (consumer.products) {
        productPrice = consumer.products.find(
          (p) => p.product_id === highestRebateMerchantProductId,
        ).product_price;
      }
    }

    const flowSteps = getFlowSteps(
      consumer.paid_for_rebates_deposit,
      consumer.installation_available_windows,
      highestRebateAmount,
    );
    setFlowSteps(flowSteps);

    setIframeUrl(
      `https://data-only.onrender.com/?merchantId=${merchantId}&productId=${highestRebateProductId}&productPrice=${productPrice}&zipCode=${zipCode}&onlyRebates=true`,
    );
    console.log(iframeUrl);

    logFrontendMetric('companion_dashboard_load', consumer.id, sessionId, merchantId, source);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // ex u: d114ac96-3603-4a69-aee5-26f502e2b84b
    const uuid = urlParams.get('u');
    const source = urlParams.get('src');

    window.addEventListener('message', (event) => {
      if (event.data.message !== 'height changed') {
        return;
      }
      console.log(event.data.height);
      setDynamicContentHeight(event.data.height + 10); // add a bit of buffer to ensure no scroll
    });

    fetchData({ uuid, source });
  }, []);

  const onLoginSubmit = async (event) => {
    event.preventDefault();
    console.log('login email', loginEmail);
    logFrontendMetric('companion_dashboard_login_attempt', null, sessionId, null, null, loginEmail);
    fetchData({ email: loginEmail });
  };

  const onRebatePaymentSuccess = async () => {
    // close the modal and show success state in the rebates module UI
    setTimeout(() => {
      setIsRebatesModalOpen(false);
    }, 2000);
    setIsRebatesPaid(true);

    // call API setting rebates paid to true for this consumer
    await submitPaidForRebatesDeposit(consumerId);
  };

  const onBlockUnselected = (blockId) => {
    const copy = Object.assign({}, selectedBlocks); // IMPORTANT MUST COPY IT FOR REACT TO WORK!
    copy[calendarValue] = copy[calendarValue].map((block) => {
      if (block.id === blockId) {
        console.log('unselecting block ');
        block.selected = false;
      }
      return block;
    });
    setSelectedBlocks(copy);
  };

  const onBlockSelected = (blockId) => {
    setSubmitError(null);
    const copy = Object.assign({}, selectedBlocks);
    copy[calendarValue] = copy[calendarValue].map((block) => {
      if (block.id === blockId) {
        console.log('selecting block ');
        block.selected = true;
      }
      return block;
    });
    setSelectedBlocks(copy);
  };

  const onClickSubmitAvailable = async () => {
    // submit avail
    const datesPicked = Object.keys(selectedBlocks).some((key) => {
      return selectedBlocks[key].some((item) => item.selected === true);
    });
    if (datesPicked) {
      // submit schedule to backend API
      setShowSubmittedDates(true);
      const availableBlocks = Object.keys(selectedBlocks)
        .filter((key) => selectedBlocks[key].some((item) => item.selected))
        .map((key) => {
          return {
            [key]: selectedBlocks[key].filter((block) => block.selected),
          };
        });
      await submitInstallationAvailableWindows(availableBlocks, consumerId);
    } else {
      setSubmitError('Must select at least one time window');
    }
  };

  const disableWeekends = ({ date, view }) => {
    // Disable dates if it's a month view and the day is Saturday (6) or Sunday (0)
    return view === 'month' && (date.getDay() === 0 || date.getDay() === 6);
  };

  const getDetailsContent = () => {
    const activeStep = flowSteps.length > 0 && flowSteps.find((step) => step.isActive).id;

    if (activeStep === 'schedule_install') {
      return (
        <div>
          <div class="bg-[#f1faf1] relative w-full max-w-[1000px] max-h-[1000px] mx-auto rounded-lg overflow-hidden shadow-lg mt-5 flex flex-col">
            <div className="px-2">
              <div className="text-3xl font-bold pb-2 px-2 pt-2">
                {showSubmittedDates ? 'Thanks for Submitting Availability' : 'Schedule Install'}
              </div>
              {showSubmittedDates ? null : (
                <div className="px-2 pb-2">
                  Upfront finds you high quality, affordable installers in your area that are
                  available on your schedule. Submit your availability for a contractor visit below:
                </div>
              )}
            </div>
            {showSubmittedDates ? (
              <div className="mx-auto px-5 md:text-center w-full">
                <div>
                  A member of our team will reach out to you over email within 1 business day with
                  available installers. <br />
                  <br />
                  <u>Your submitted availability:</u>{' '}
                </div>
                {Object.keys(selectedBlocks).map((key) => {
                  return (
                    <div className="md:mx-auto text-md w-3/4 md:w-1/2 my-3 rounded-lg" key={key}>
                      {selectedBlocks[key].find((block) => block.selected) ? (
                        <div className="font-bold text-gray-700">{formatDateString(key)}</div>
                      ) : null}
                      {selectedBlocks[key].map((block, index) => {
                        if (block.selected) {
                          return (
                            <div className="text-gray-600 mt-2" key={index}>
                              {block.text}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div class="bg-white w-full flex-grow py-5">
                <div className="mx-auto text-center font-bold">
                  Step 1: Select available day(s):
                </div>
                <Calendar
                  tileDisabled={disableWeekends}
                  minDate={threeDaysFromToday}
                  maxDate={twoWeeksFromToday}
                  className="mx-auto mt-3 mb-3"
                  onChange={onCalendarChange}
                  value={calendarValue}
                />
                <div className="mx-auto mt-3 mb-3 text-center font-bold">
                  Step 2: Select available time window(s):
                </div>
                {selectedBlocks[calendarValue] &&
                  selectedBlocks[calendarValue].map((block) => {
                    console.log('block obj', block);
                    if (block.selected) {
                      console.log('rendering SELECTED');
                      // render the selected variant
                      return (
                        <button
                          class="group my-2 mx-auto bottom-3 flex w-4/5 md:w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => onBlockUnselected(block.id)}
                        >
                          {block.text}
                        </button>
                      );
                    } else {
                      console.log('rendering UNSELECTED');
                      // render the unselected variant
                      return (
                        <button
                          class="group my-2 mx-auto bottom-3 flex w-4/5 md:w-1/2 justify-center rounded-md border border-transparent bg-[#f1faf1] py-3 px-4 text-lg font-medium text-black hover:bg-[#14532d] hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => onBlockSelected(block.id)}
                        >
                          {block.text}
                        </button>
                      );
                    }
                  })}
                <div className="my-5 border-t border-solid border-[#d3d3d3] w-3/4 md:w-1/2 mx-auto"></div>
                {Object.keys(selectedBlocks).map((key) => {
                  return (
                    <div className="mx-auto text-sm w-3/4 md:w-1/2 my-3 rounded-lg" key={key}>
                      {selectedBlocks[key].find((block) => block.selected) ? (
                        <div className="font-bold text-gray-700">{formatDateString(key)}</div>
                      ) : null}
                      {selectedBlocks[key].map((block, index) => {
                        if (block.selected) {
                          return (
                            <div className="text-gray-600 mt-2" key={index}>
                              {block.text}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
                {submitError ? (
                  <div className="mx-auto text-center mb-5 text-red-500">{submitError}</div>
                ) : null}
                <button
                  class="group divide-y mx-auto bottom-3 flex w-4/5 md:w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onClickSubmitAvailable}
                >
                  Submit availability <ChevronRightIcon className="h-7 w-7" />
                </button>
              </div>
            )}
          </div>
        </div>
      );
    } else if (activeStep === 'get_rebates') {
      return (
        <div>
          <div class="bg-[#f1faf1] relative w-full max-w-[1000px] mx-auto overflow-hidden shadow-lg mt-5">
            <div className="text-3xl font-bold px-2 pt-2 pb-2">
              Your Rebates (in ZIP Code: {zipCode})
            </div>
            <div>
              {hideRebatesCTA ? (
                <div className="px-2 pb-2">See your available rebates below:</div>
              ) : (
                <div className="px-2">
                  Upfront Rebate Pro makes getting your rebate easy. With just a $1 deposit and
                  simple 10% success fee, we only win if you win:
                </div>
              )}
            </div>
            {isRebatesPaid ? (
              <div class="bg-[#f1faf1] w-full border-t border-gray-200 py-10 mt-5">
                <div className="mx-10 text-center font-bold text-lg">
                  Thanks, we'll reach out over email shortly with next steps!
                </div>
              </div>
            ) : hideRebatesCTA ? null : (
              <div class="bg-[#f1faf1] w-full mt-5 pb-5">
                <div class="grid grid-cols-2 gap-1 mt-5 mb-5 mx-5 w-full md:w-1/2 mx-auto place-items-center md:gap-4 text-[#166534]">
                  <div>
                    <svg
                      class="inline w-6 h-6 text-[#166534] mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    no paperwork
                  </div>
                  <div>
                    <svg
                      class="inline w-6 h-6 text-[#166534] mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    save time
                  </div>
                  <div>
                    <svg
                      class="inline w-6 h-6 text-[#166534] mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    more savings
                  </div>
                  <div>
                    <svg
                      class="inline w-6 h-6 text-[#166534] mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    try for $1
                  </div>
                </div>
                <button
                  onClick={() => {
                    logFrontendMetric(
                      'companion_dashboard_get_help_rebates_click',
                      consumerId,
                      sessionId,
                      merchantId,
                    );
                    setIsRebatesModalOpen(true);
                  }}
                  class="group mx-auto bottom-3 flex w-3/4 md:w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Get Upfront Rebate Pro
                </button>
              </div>
            )}
            <div class="w-full bg-white pt-5">
              {iframeUrl ? (
                <iframe
                  className=""
                  width="100%"
                  height={dynamicContentHeight}
                  src={iframeUrl}
                ></iframe>
              ) : (
                <div className="bg-white pt-20 pb-20 mx-auto w-full text-center">Loading...</div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (activeStep === 'carbon_credits') {
      return (
        <div>
          <div className="bg-[#f1faf1] relative w-full max-w-[1000px] max-h-[1000px] mx-auto rounded-lg overflow-hidden shadow-lg mt-5 flex flex-col">
            <div className="text-3xl font-bold pb-2 pt-2 px-2">Coming soon</div>
            <div className="px-2 pb-2">
              We're always adding new features and love hearing what you want next. What would you
              like help with? Check all that apply.
            </div>
            <form onSubmit={handleComingSoonSubmit} className="flex-grow w-full bg-white">
              <div className="flex flex-col p-4">
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    name="carbon_credits"
                    checked={selections.carbon_credits}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 text-gray-700">
                    Sell my equipment’s carbon credits for me (pilot program{' '}
                    <a
                      target="_blank"
                      href={`https://knowupfront.typeform.com/to/yivv88KS#email=${loginEmail}`}
                      onClick={() =>
                        logFrontendMetric(
                          'companion_dashboard_open_carbon_credit',
                          consumerId,
                          sessionId,
                          merchantId,
                        )
                      }
                      className="underline text-[#166534] font-bold"
                    >
                      open
                    </a>
                    )
                  </span>
                </label>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    name="financing"
                    checked={selections.financing}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 text-gray-700">Financing for my installation</span>
                </label>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    name="warranty"
                    checked={selections.warranty}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 text-gray-700">Extend my product's warranty</span>
                </label>
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    name="energySavings"
                    checked={selections.energySavings}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                  <span className="ml-2 text-gray-700">Help me save on my energy bill</span>
                </label>
              </div>
              <div className="bg-white bottom-0 left-0 w-full border-t border-gray-200 py-5">
                {isComingSoonSubmitted ? (
                  <div className="mx-10 text-center">Thanks, we'll be in touch to help you.</div>
                ) : (
                  <button
                    type="submit"
                    className="group mx-auto bottom-3 flex w-1/2 justify-center rounded-md border border-transparent bg-[#166534] py-3 px-4 text-lg font-medium text-white hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      );
    }
  };

  const onStepClick = (stepId) => {
    const newFlowSteps = flowSteps.map((step) => {
      step.isActive = step.id === stepId;
      return step;
    });
    setFlowSteps(newFlowSteps);
  };

  if (showLoginPage) {
    return (
      <div class="h-screen flex items-center justify-center bg-white">
        <div class="absolute top-0 left-0 m-5">
          <img src="/upfront_logo_white.png" alt="Logo" class="h-12" />
        </div>

        <div class="w-full max-w-xs">
          <form onSubmit={onLoginSubmit} class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                Email
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                onChange={(e) => setLoginEmail(e.target.value)}
                value={loginEmail}
                id="email"
                type="text"
                placeholder="Email address"
              />
            </div>
            <div class="flex items-center justify-between">
              <button
                class="bg-[#166534] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                View Companion Dashboard
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div class="flex flex-col justify-center items-center">
      <div class="w-full text-center md:text-left relative md:fixed top-1 left-1 md:top-5 md:left-5">
        <img src="/upfront_logo_white.png" class="mx-auto md:mx-0 mt-0 w-[150px]" alt="Logo" />
      </div>
      <Modal
        isOpen={isRebatesModalOpen}
        isGreen={true}
        onClose={() => setIsRebatesModalOpen(false)}
      >
        <div class="container mx-auto px-4">
          <div className="text-3xl font-bold">Rest Easy Rebates</div>
          <h1 className="pb-4 text-md pt-4">
            Upfronts Rebate Pro makes getting your rebate easy. With just a $1 deposit and simple
            10% success fee, we only win if you win.
          </h1>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pb-8 pt-4">
            <div class="bg-[#166534] text-white p-2 rounded text-center">
              <p>We navigate rebate requirements for you and your installer</p>
            </div>
            <div class="bg-[#166534] text-white p-2 rounded text-center">
              <p>You upload any documents needed and we fill out the paperwork</p>
            </div>
            <div class="bg-[#166534] text-white p-2 rounded text-center">
              <p>We don’t win unless you do – we only get paid if you get your rebate</p>
            </div>
          </div>
        </div>
        <StripeCheckout onSuccess={onRebatePaymentSuccess} />
      </Modal>
      <div class="grid grid-cols-4 gap-1 my-5 mx-5 relative min-h-[50px]">
        {productNames.map((product, i) => (
          <>
            {i === 0 || expanded ? (
              <>
                <div class="flex flex-col justify-center">
                  {product.type === 1 ? (
                    <img src="/evse.png" class="mx-auto md:mx-0 mt-0 w-[40px]" alt="Logo" />
                  ) : (
                    <img src="/heat_pump.png" class="mx-auto md:mx-0 mt-0 w-[40px]" alt="Logo" />
                  )}
                </div>
                <div class="flex flex-col col-span-2 justify-center">
                  <div className="text-base font-semibold leading-6 text-gray-900">
                    {product.name}
                  </div>
                  <div>{purchaseDate}</div>
                </div>
              </>
            ) : null}
            {i === 0 ? (
              <div class="flex flex-col justify-center">
                <img src={merchantImage} class="mx-auto md:mx-0 mt-0 w-[100px]" alt="Logo" />
              </div>
            ) : (
              <div></div>
            )}
            {!expanded && (
              <div
                className="absolute cursor-pointer bottom-[-30px] left-[-5px] p-2"
                onClick={() => setExpanded(true)}
              >
                <ChevronDownIcon className="h-6 w-6" />
              </div>
            )}
            {expanded && (
              <div
                className="absolute cursor-pointer bottom-[-30px] left-[-5px] p-2"
                onClick={() => setExpanded(false)}
              >
                <ChevronUpIcon className="h-6 w-6" />
              </div>
            )}
          </>
        ))}
      </div>
      <div className="bg-[#f8f8f8] relative min-h-screen w-full h-full py-10 px-5">
        <div className="max-w-[1000px] max-h-[1000px] mx-auto">
          <div className="text-4xl font-bold text-[#166534] pb-2">Hi {firstName},</div>
          <div className="pb-5">
            Welcome to Upfront Companion — the easiest and most affordable way to start using your
            new product:
          </div>
          <div class="flex flex-wrap bg-white shadow-md rounded">
            <div class="w-2/3">
              <ul>
                {flowSteps.map((step) => {
                  if (step.status === 'Complete') {
                    return <li class="bg-[#ebeaea] p-4">{step.name}</li>;
                  } else if (step.isActive) {
                    return (
                      <li
                        onClick={() => onStepClick(step.id)}
                        class="bg-[#166534] text-white cursor-pointer p-4"
                      >
                        <ChevronRightIcon className="inline h-5 w-5" />
                        {step.name}
                      </li>
                    );
                  }
                  return (
                    <li onClick={() => onStepClick(step.id)} class="p-4 cursor-pointer">
                      <ChevronRightIcon className="inline h-5 w-5" />
                      {step.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div class="w-1/3">
              <ul>
                {flowSteps.map((step) => {
                  if (step.status === 'Complete') {
                    return <li class="bg-[#ebeaea] p-4">{step.status}</li>;
                  } else if (step.isActive) {
                    return (
                      <li
                        onClick={() => onStepClick(step.id)}
                        class="bg-[#166534] text-white cursor-pointer p-4"
                      >
                        {step.status}
                      </li>
                    );
                  }
                  return (
                    <li onClick={() => onStepClick(step.id)} class="p-4 cursor-pointer">
                      {step.status}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {getDetailsContent()}
        <div className="pt-10 text-center text-[#166534] font-bold">
          Need help? Reach out at companion@knowupfront.com
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
