import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import { object, string } from 'yup';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { PreviousButton } from './Components/PreviousButton';

const Qualification = ({ supabase, qualificationQuestions, onComplete, onBack }) => {
  const [yesSelected, setYesSelected] = useState({});
  const [noSelected, setNoSelected] = useState({});

  const STEPS = [
    { name: '1. Your Rebates', status: 'active' },
    { name: '2. Apply', status: '' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  const onContinueQualification = async (values) => {
    // Convert yes/no replies into boolean
    const boolValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value === 'yes']),
    );
    onComplete(boolValues);
  };

  // Generate initial values for question and Y/N responses
  const initialValues = {};
  // Dynamic form validationSchema generation
  const validationObject = {};
  qualificationQuestions.forEach((q) => {
    if (q.type === 'boolean') {
      validationObject[q.name] = string()
        .oneOf(['yes', 'no'])
        .required(`This question is required`);
      initialValues[q.name] = '';
    }
  });
  const validationSchema = object(validationObject);

  const YesButton = ({ questionName, form }) => {
    return (
      <button
        type="button"
        onClick={() => {
          form.setFieldValue(questionName, 'yes');
          setYesSelected({ ...yesSelected, [questionName]: true });
          setNoSelected({ ...noSelected, [questionName]: false });
        }}
        className="relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none"
        style={yesSelected[questionName] ? { backgroundColor: '#166534', color: '#ffffff' } : {}}
      >
        Yes
      </button>
    );
  };

  const NoButton = ({ questionName, form }) => {
    return (
      <button
        type="button"
        onClick={() => {
          form.setFieldValue(questionName, 'no');
          setYesSelected({ ...yesSelected, [questionName]: false });
          setNoSelected({ ...noSelected, [questionName]: true });
        }}
        className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
        style={noSelected[questionName] ? { backgroundColor: '#166534', color: '#ffffff' } : {}}
      >
        No
      </button>
    );
  };

  // Dynamically generate questions and fields for answering, based on question type.
  const dynamicQuestions = qualificationQuestions.map((q) => {
    if (q.type === 'boolean') {
      // Show Question Text and Yes/No buttons
      return (
        <div
          key={q.id}
          className="justify-around sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5"
        >
          {/* Question Text */}
          <div className="col-span-2 block">
            <label htmlFor={q.name} className="text-l font-medium text-gray-700 sm:mt-px sm:pt-2">
              {q.full_text}
            </label>
            <div className="text-red-500">
              <ErrorMessage name={q.name} />
            </div>
          </div>

          {/* Yes/No Buttons */}
          <Field
            name={q.name}
            id={q.name}
            type="button"
            className="col-span-1 isolate inline-flex rounded-md shadow-sm"
          >
            {({ field, form, meta }) => (
              <div className="flex justify-end">
                <YesButton questionName={q.name} form={form} />
                <NoButton questionName={q.name} form={form} />
              </div>
            )}
          </Field>
        </div>
      );
    }
  });

  return (
    <div className="min-h-screen flex flex-col">
      <div className="mb-6 mt-4">
        <StepsCircles steps={STEPS} />
      </div>

      <div className="flex flex-col flex-1 max-w-xl mx-auto mb-6">
        <h1 className="text-black text-4xl text-left font-bold mb-4">See what you qualify for</h1>

        <p className="mt-2 text-left text-lg text-gray-600">
          <span className="font-large text-gray-600">
            We have a few questions to find all of the rebate money that you're entitled to.
          </span>
        </p>

        <div className="flex min-h-full items-center justify-center pb-4 px-4 sm:px-6 lg:px-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              onContinueQualification(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="login-form">
                <div className="content-container max-h-[calc(100vh-200px)] overflow-y-auto p-4">
                  {dynamicQuestions}
                  <br />
                </div>

                <div className="button-container fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
                  <div className="w-full mb-2">
                    <ContinueButton isLoading={isSubmitting} />
                    <PreviousButton isLoading={isSubmitting} onClick={onBack} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Qualification;
