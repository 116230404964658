import React, { useState, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import StepsCircles from './Components/StepsCircles';
import { Loading } from './Components/Loading';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ContinueButton } from './Components/ContinueButton';
import { bool, object } from 'yup';

const AutoPay = ({ supabase, borrowerId, loanId, onComplete, applicantInfo, testMode, env }) => {
  const [linkToken, setLinkToken] = useState(null);
  const [isBankAdded, setIsBankAdded] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [bankMetadata, setBankMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [plaidPublicToken, setPlaidPublicToken] = useState('');
  const [plaidMetadata, setPlaidMetadata] = useState(null);
  const [bankError, setBankError] = useState(null);

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'completed' },
    { name: '3. Approval', status: 'completed' },
    { name: '4. Finish Up', status: 'active' },
  ];

  const schema = object({
    termsAccepted: bool().oneOf([true], 'You must read and agree to the loan agreement'),
  });

  const name = applicantInfo.firstName + ' ' + applicantInfo.lastName;

  const fetchLinkToken = async () => {
    console.log('borrower id', borrowerId);
    const createLinkTokenResponse = await supabase.functions.invoke(
      'bnpl_plaid_create_link_token',
      {
        body: {
          user_id: borrowerId,
          env: env,
        },
      },
    );
    console.log('date reply', createLinkTokenResponse);

    if (!createLinkTokenResponse.data.is_success) {
      console.error("Couldn't create Plaid link token");
      return;
    }

    const token = createLinkTokenResponse.data.link_token;
    setLinkToken(token);
  };

  useEffect(() => {
    fetchLinkToken();
    setIsPreLoading(false);
  }, []);

  const onPlaidSuccess = async (publicToken, metadata) => {
    setPlaidPublicToken(publicToken);
    setPlaidMetadata(metadata);
    setBankMetadata(`${metadata.account.name} - ${metadata.account.mask}`);
    setBankError(null);
  };

  const { open: openPlaid, ready: readyPlaid } = usePlaidLink({
    token: linkToken,
    onSuccess: onPlaidSuccess,
  });

  const onEnableAutoPay = () => {
    openPlaid();
    setIsBankAdded(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const onContinueAutopay = async () => {
    const exchangePublicTokenResponse = await supabase.functions.invoke(
      'bnpl_plaid_exchange_public_token',
      {
        body: {
          public_token: plaidPublicToken,
          user_id: borrowerId,
          loan_id: loanId,
          bank_display_name: `${plaidMetadata.account.name} - ${plaidMetadata.account.mask}`,
          env: env,
        },
      },
    );
    const result = exchangePublicTokenResponse.data;

    if (result.is_success) {
      console.log(plaidMetadata);
      onComplete();
    } else {
      setBankError('Something went wrong. Please try again.');
      setIsLoading(false);
      setPlaidPublicToken('');
      setPlaidMetadata(null);
      setBankMetadata(null);
    }
  };

  const onSkipAutopay = async () => {
    onComplete();
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="relative top-2 w-full max-w-xl mx-auto mb-6">
        <StepsCircles steps={STEPS} />
      </div>

      {isPreLoading ? (
        <Loading text="Loading..." />
      ) : (
        <>
          <div className="flex-grow w-full max-w-xl mx-auto mb-6 mt-4">
            <h1 className="text-black text-4xl text-left font-bold mb-4 mt-2">
              Let's setup autopay
            </h1>

            <p className="text-gray-700 text-xl mb-4">
              You <span className="font-bold"> will not be</span> charged today.
            </p>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Account holder name</span>
                <span className="text-black font-bold">{name}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Installment Amount</span>
                <span className="text-black font-bold">${applicantInfo.totalInstallments}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Start Date</span>
                <span className="text-black font-bold">
                  {formatDate(applicantInfo.first_payment_date)}
                </span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-black font-bold text-xl">Select payment method</span>
              </div>
              <hr className="my-2 bg-gray-100 h-0.5 border-none" />
            </div>

            <div className="flex items-center justify-between mb-4">
              {isBankAdded && !bankError ? (
                <div>
                  <span className="flex items-center justify-center">
                    <span>{bankMetadata}</span>
                  </span>
                </div>
              ) : (
                <div className="w-full">
                  <div className="flex items-center w-full">
                    <button
                      onClick={onEnableAutoPay}
                      disabled={!readyPlaid}
                      className="flex items-center border-[#166534] bg-white text-[#166534] py-2 px-4 rounded-lg hover:bg-[#166534] hover:text-white transition duration-300 active:bg-[#166534] active:text-white shadow-lg hover:shadow-xl"
                    >
                      <span className="ml-2 mr-3 text-xl text-black">Add your bank account</span>
                    </button>
                    <div className="ml-auto text-[#166534] bg-[#c8e6c9] px-1 py-0.5 rounded-md text-xs">
                      Recommended
                    </div>
                  </div>
                  <div className="text-red-500">{bankError && <p>{bankError}</p>}</div>
                </div>
              )}
            </div>

            <div className="text-gray-500 text-sm mb-2">
              <hr className="my-2 bg-gray-100 h-0.5 border-none" />
              <p className="text-gray-500 text-sm mb-2">
                A monthly installment of ${applicantInfo.totalInstallments} will be deducted from
                your account on the installment due date. You can revoke authorization of this at
                any time through your Upfront Dashboard.
              </p>
              <hr className="my-2 bg-gray-200 h-0.5 border-none" />
              <br></br>
              <br></br>

              <Formik
                initialValues={{
                  termsAccepted: false,
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  if (!bankMetadata) {
                    // User didn't add bank account yet, show an error
                    setBankError(
                      'You need to add your bank account to enable autopay at this moment',
                    );
                    return;
                  }
                  setIsLoading(true);
                  onContinueAutopay();
                }}
              >
                <Form>
                  <div className="flex items-center mt-4">
                    <Field name="termsAccepted" id="termsAccepted" type="checkbox" />
                    <label htmlFor="termsAccepted" className="text-[#166534] flex-shrink-0">
                      I have read and agree to{' '}
                      <span className="font-bold underline">Payment Authorization</span> which
                      governs the use of automatic payments.
                    </label>
                  </div>
                  <div className="text-red-500">
                    <ErrorMessage name="termsAccepted" />
                  </div>
                  <br></br>

                  <div className="button-container bg-white shadow-md p-4 absolute bottom-0 left-0 right-0">
                    <div className="w-full mb-2">
                      <ContinueButton isLoading={isLoading} text="Enable autopay" />
                    </div>
                    <div className="w-full">
                      <button
                        onClick={onSkipAutopay}
                        type="submit"
                        className="group flex items-center justify-center w-full rounded-md border border-transparent text-[#166534] py-2 px-4 text-sm font-medium hover:bg-[#14532d] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Skip autopay
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AutoPay;
