import React, { useEffect, useState } from 'react';
import { BiRightArrow } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { PreviousButton } from './Components/PreviousButton';

const Complete = ({ onBack }) => {
  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'completed' },
    { name: '3. Approval', status: 'completed' },
    { name: '4. Finish Up', status: 'active' },
  ];

  const onContinueMerchant = async () => {
    console.log('back to merchant!');
    window.parent.postMessage(
      {
        message: 'upfront pay exit',
      },
      '*',
    );
  };

  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      <StepsCircles steps={STEPS} />
      <br></br>
      <br></br>{' '}
      <div className="max-w-xl mx-auto text-center">
        <h1 className="text-4xl font-bold mb-4 flex items-center justify-center">
          <div className="w-10 h-10 rounded-full bg-white border-2 border-[#166534] flex items-center justify-center text-[#166534] mr-2">
            <FaCheck className="w-5 h-5 text-[#166534]" />
          </div>
          Great! you're all set.
        </h1>
        <p className="text-gray-700">
          View your rebates and installment plans from your{' '}
          <span className="text-[#166534] underline font-bold">Upfront Dashboard</span>. We will
          email you this link, and you'll log in with your mobile number.
        </p>
        <div className="button-container fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
          <div className="w-full mb-2">
            <ContinueButton isLoading={false} onClick={onContinueMerchant} />
            <PreviousButton isLoading={false} onClick={onBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
