import React, { useEffect, useState } from 'react';
import { PaymentBar } from './Components/PaymentBar';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import StepsCircles from './Components/StepsCircles';
import { Loading } from './Components/Loading';
import { ContinueButton } from './Components/ContinueButton';
import { PreviousButton } from './Components/PreviousButton';

const InstantRebate = ({
  supabase,
  applicationId,
  acceptedOfferId,
  onComplete,
  applicationResponse,
  applicantInfo,
  onBack,
  testMode,
  prevMode,
  env,
}) => {
  console.log('applicationResponse Data:', applicationResponse);
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [agreementReady, setAgreementReady] = useState(false); // Track if the document is ready

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'completed' },
    { name: '3. Approval', status: 'active' },
    { name: '4. Finish Up', status: '' },
  ];

  const {
    apr,
    financed_amount,
    purchase_amount_cents,
    monthly_payment,
    number_of_payments,
    total_interest_cents,
    first_payment_date,
    instant_rebate_amount_cents,
  } = applicationResponse;

  const totalInterestDollars = total_interest_cents / 100;
  const rebateAmountDollars = instant_rebate_amount_cents / 100;

  const monthlyPaymentDollars = monthly_payment / 100;
  const purchaseAmountDollars = purchase_amount_cents / 100;
  const financedAmountDollars = financed_amount / 100;

  const totalInstallments = financedAmountDollars + totalInterestDollars;
  applicantInfo.totalInstallments = monthlyPaymentDollars.toFixed(2);
  applicantInfo.first_payment_date = first_payment_date;

  const onContinueApproved = async () => {
    setIsLoading(true);
    if (agreementReady) {
      onComplete(AgreementResponse, applicantInfo);
    }
  };

  const [AgreementResponse, setAgreementResponse] = useState(null);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        console.log('loading pdf...');
        const agreementResponse = await supabase.functions.invoke('private_new_loan_agreement', {
          body: {
            applicationId: applicationId,
            acceptedOfferId: acceptedOfferId,
            env: env,
          },
        });

        console.log('agreementResponse', agreementResponse);
        if (agreementResponse.error) {
          // TODO: handle the error in the UI
          console.error('error with pdf call', agreementResponse.error);
        } else {
          //   console.log("pdf data", agreementResponse.data.document_url);
          if (agreementResponse.data.document_url) {
            setAgreementResponse(agreementResponse.data);
            setAgreementReady(true); // Document is ready
          }
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      }
    };

    if (!testMode && !prevMode) {
      loadPDF();
    } else {
      // setAgreementResponse({
      //   document_url:
      //     'https://assets.speakcdn.com/assets/2703/bulletin-may26.pdf',
      // });
      setAgreementReady(true); // Document is ready
    }
    setIsPreLoading(false);
  }, []);

  return (
    <div>
      <div className="relative top-2 w-full max-w-xl mx-auto mb-6">
        <StepsCircles steps={STEPS} />
      </div>

      {isPreLoading ? (
        <Loading text="Getting Application Result..." />
      ) : !agreementReady ? ( // Display loading until document is ready
        <Loading text="Loading Agreement Document..." />
      ) : (
        <>
          <br></br>
          <br></br>
          <div className="max-w-xl mx-auto">
            <div className="flex flex-col w-full">
              <h1 className="text-black text-4xl text-left font-bold mb-4">You're Approved!</h1>
              <br></br>
            </div>

            <div className="max-w-xl mx-auto mb-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Purchase Amount</span>
                <span className="text-gray-700">${purchaseAmountDollars.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700 font-bold">Instant Rebate</span>
                <span className="text-green-500 font-bold">
                  - ${rebateAmountDollars.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-500">
                  We handle the paperwork to save you money.
                </span>
              </div>

              <div className="mb-2">
                <hr className="border-t border-gray-300" />
              </div>
              <br></br>

              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-500">Net Amount</span>
                <span className="text-black font-bold">${financedAmountDollars.toFixed(2)}</span>
              </div>
            </div>
            <br></br>

            <div className="bg-gray-100 border-2  p-4 rounded-md mb-4 h-35">
              <p className="text-black text-xl text-left mb-4">
                <span className="font-bold">${monthlyPaymentDollars.toFixed(2)} / month</span> for{' '}
                {number_of_payments} months
              </p>

              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Interest ({apr}% APR)</span>
                <span className="text-gray-700">${totalInterestDollars}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700">Total Installments</span>
                <span className="text-black font-bold">${totalInstallments}</span>
              </div>
            </div>

            <p className="font-bold text-lg">Payment Schedule</p>
            <br></br>
            <p className="font-semibold">12 total payments</p>

            <br></br>
            <PaymentBar
              firstPaymentDate={first_payment_date}
              monthlyPayment={monthlyPaymentDollars}
              numberOfPayments={number_of_payments}
            />

            <br></br>
            <br></br>
            <div className="max-w-xl mx-auto">
              <ContinueButton
                onClick={onContinueApproved}
                text="Accept installment offer"
                isLoading={isLoading}
              />
              <PreviousButton isLoading={isLoading} onClick={onBack} />
            </div>

            <br></br>
          </div>
        </>
      )}
    </div>
  );
};

export default InstantRebate;
