export function extractZipCode(str) {
  const zipCodePattern = /\b\d{5}\b/;
  const matches = str.match(zipCodePattern);
  return matches ? matches[0] : null;
}

// Same function as in Upfront.js
export function getOrCreateSession() {
  const MONTH_IN_SECONDS = 2592000; // 30 days in seconds

  const savedSessionId = localStorage.getItem('upfrontSessionId');
  const savedSessionStartTime = localStorage.getItem('upfrontSessionStartTime');
  const unixtimeNow = Date.now() / 1000;

  let sessionId;

  if (
    savedSessionId &&
    savedSessionStartTime &&
    unixtimeNow - savedSessionStartTime < MONTH_IN_SECONDS
  ) {
    console.log('using saved values', localStorage);
    sessionId = savedSessionId;
  } else {
    sessionId = crypto.randomUUID();
    localStorage.setItem('upfrontSessionId', sessionId);
  }
  // Refresh session start time
  localStorage.setItem('upfrontSessionStartTime', unixtimeNow.toString());

  return sessionId;
}
