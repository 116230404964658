import React, { useState } from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';

export const PaymentBar = ({ firstPaymentDate, monthlyPayment, numberOfPayments }) => {
  const [expanded, setExpanded] = useState(false);

  const generatePayments = () => {
    let parsedDate = parse(firstPaymentDate, 'EEE MMM d yyyy', new Date());

    let formattedDate = format(parsedDate, 'MMMM d, yyyy');

    const payments = [];
    let currentDate = parse(formattedDate, 'MMMM d, yyyy', new Date());

    for (let i = 0; i < numberOfPayments; i++) {
      payments.push({
        date: format(currentDate, 'MMMM d, yyyy'),
        amount: `$${parseFloat(monthlyPayment).toFixed(2)}`,
      });
      currentDate = addMonths(currentDate, 1);
    }

    return payments;
  };

  const payments = generatePayments();

  return (
    <div className="pl-4 relative">
      {payments.map((payment, index) => {
        if (expanded) {
          return renderPayment(payment, index);
        } else {
          if (
            index === 0 ||
            index === 1 ||
            index === payments.length - 2 ||
            index === payments.length - 1
          ) {
            return renderPayment(payment, index);
          } else if (index === 2) {
            return (
              <div
                key={index}
                className="ml-8 text-sm mt-2 mb-4 text-gray-600 cursor-pointer relative"
                onClick={() => setExpanded(true)}
              >
                + {numberOfPayments - 4} more payments
              </div>
            );
          }
        }
        return null;
      })}
      {expanded && (
        <div
          className="ml-8 text-sm mt-2 text-blue-500 cursor-pointer"
          onClick={() => setExpanded(false)}
        >
          Show less
        </div>
      )}
    </div>
  );
  function renderPayment(payment, index) {
    return (
      <div
        key={index}
        className="flex justify-between pb-4 relative"
        style={{ alignItems: 'baseline' }}
      >
        <div
          className="absolute left-0 top-0 h-full w-0.5 bg-gray-300 z-10 transform -translate-x-1/2"
          style={index === 1 && !expanded ? { height: 'calc(100% + 3rem)' } : {}}
        ></div>
        <div className="absolute left-0 top-[30%] transform -translate-y-1/2 -translate-x-1/2 w-2.5 h-2.5 rounded-full z-20 bg-[#166534]"></div>
        <div className="absolute left-0 top-[30%] transform -translate-y-1/2 -translate-x-1/2 w-1.5 h-1.5 bg-white rounded-full z-30"></div>

        <div className={`ml-8 text-gray-600`}>
          {index === 0 && (
            <div className="text-black font-bold" style={{ height: '20px' }}>
              First invoice:
            </div>
          )}
          <div>{payment.date}</div>
        </div>
        <span className={`text-right text-gray-600`}>{payment.amount}</span>
      </div>
    );
  }
};
