import React from 'react';
import { FaSpinner } from 'react-icons/fa';

export const Loading = ({ text }) => {
  return (
    <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
      {/* <Nav /> */}
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-[#166534] text-2xl">
          <FaSpinner className="animate-spin inline mr-2" /> {text}
        </div>
      </div>
    </div>
  );
};
