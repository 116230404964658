import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import PhoneInput from 'react-phone-input-2';
import { Loading } from './Components/Loading';
import StepsCircles from './Components/StepsCircles';
import { ContinueButton } from './Components/ContinueButton';
import { PreviousButton } from './Components/PreviousButton';

const PhoneNumber = ({
  supabase,
  qualificationData,
  installationPrice,
  merchantId,
  productPrice,
  eligiblePrograms,
  onComplete,
  onBack,
  noRebateBool,
  testMode,
  env,
}) => {
  const [months, setMonths] = useState(null);
  const [isPreLoading, setIsPreLoading] = useState(true);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [programData, setProgramData] = useState({
    productDiscount: 0,
    installationDiscount: 0,
    programs: [],
    instantProductDiscount: 0,
    instantInstallationDiscount: 0,
  });

  const STEPS = [
    { name: '1. Your Rebates', status: 'completed' },
    { name: '2. Apply', status: 'active' },
    { name: '3. Approval', status: '' },
    { name: '4. Finish Up', status: '' },
  ];

  useEffect(() => {
    getInfo().then((data) => {
      setProgramData(data);

      setIsPreLoading(false); // Set isPreLoading to false once data is fetched
    });
  }, []);

  useEffect(() => {
    const getMonths = async () => {
      const rebatesTodayNumber = programData.productDiscount + programData.installationDiscount;
      const response = await supabase.functions.invoke('get_months', {
        body: {
          productPrice,
          rebatesTodayNumber,
        },
      });
      // console.log("month reply", response)
      setMonths(response.data);
    };
    getMonths();
  }, [programData]);

  const getInfo = async () => {
    // call API qual endpoint with zip
    const qualificationResponse = await supabase.functions.invoke('private_get_eligible_programs', {
      body: {
        qualificationData: qualificationData,
        eligiblePrograms: eligiblePrograms,
        productPrice: productPrice,
        installationPrice: installationPrice,
        merchantId: merchantId,
      },
    });
    const apiData = {
      productDiscount: qualificationResponse.data.productDiscount,
      installationDiscount: qualificationResponse.data.installationDiscount,
      programs: qualificationResponse.data.programs,
      instantProductDiscount: qualificationResponse.data.instantProductDiscount,
      instantInstallationDiscount: qualificationResponse.data.instantInstallationDiscount,
    };
    return apiData;
  };

  const onContinuePhone = async (values, rebatesToday, instantRebatesToday) => {
    if (!testMode && env !== 'sandbox') {
      // Only send actual text message if we're not in a test mode and not sandbox
      const { data, error } = await supabase.auth.signInWithOtp({
        phone: values.phoneNumber,
      });
    }

    let total;
    if (noRebateBool) {
      total = 0;
    } else if (programData.instantProductDiscount + programData.instantInstallationDiscount > 0) {
      total = instantRebatesToday;
    } else {
      total = rebatesToday;
    }

    onComplete(values.phoneNumber, total, programData);
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const schema = object({
    phoneNumber: string().min(11, 'Invalid phone number').required('Phone number is required'),
  });

  let mainCopy;
  let boxCopy;
  let middleCopy;
  let rebatesToday;
  let instantRebatesToday;

  if (noRebateBool) {
    const productPriceFormatted = moneyFormatter.format(productPrice / 100);
    middleCopy = <p></p>;
    mainCopy = (
      <h1 className="text-black text-4xl text-left font-bold mb-4 self-center">
        We have not found any rebates you are owed!
      </h1>
    );
    boxCopy = (
      <div className="flex py-2">
        <img className="h-20" src="Calendar.png" alt="Calendar" />
        <p className="text-xl ml-4 font-bold self-center">
          Your payment of <span className="text-[#48BB78]">{productPriceFormatted}</span> could be
          eligible for a loan.
        </p>
      </div>
    );
  } else {
    rebatesToday = moneyFormatter.format(
      (programData.productDiscount + programData.installationDiscount) / 100,
    );

    instantRebatesToday = moneyFormatter.format(
      (programData.instantProductDiscount + programData.instantInstallationDiscount) / 100,
    );

    middleCopy = (
      <>
        <p className="text-black text-xl text-left mb-4 font-bold">
          {programData.programs.length} available rebates
        </p>
        {programData.programs.map((program) => (
          <div className="flex justify-between items-center mb-2" key={program.name}>
            <span className="text-gray-700">{program.name}</span>
            <span className="text-gray-700">${(program.product_discount / 100).toFixed(2)}</span>
          </div>
        ))}
      </>
    );

    if (programData.instantProductDiscount + programData.instantInstallationDiscount > 0) {
      mainCopy = (
        <h1 className="text-black text-4xl text-left font-bold mb-4">
          Save <span className="text-[#48BB78]">{instantRebatesToday}</span> with an installment
          plan using your rebate!
        </h1>
      );
      boxCopy = (
        <div className="flex py-4">
          <img className="h-20" src="Group.png" alt="Group" />
          <p className="text-xl ml-4 font-bold self-center">
            Cut your upfront payment by{' '}
            <span className="text-[#48BB78]">{instantRebatesToday}</span> using your rebates.
          </p>
        </div>
      );
    } else {
      mainCopy = (
        <h1 className="text-black text-4xl text-left font-bold mb-4 self-center">
          We found <span className="text-[#48BB78]">{rebatesToday}</span> of rebates that you're
          owed!
        </h1>
      );
      boxCopy = (
        <div className="flex py-2">
          <img className="h-20" src="Calendar.png" alt="Calendar" />
          <p className="text-xl ml-4 font-bold self-center">
            <span className="text-[#48BB78]">{rebatesToday}</span> in rebates could equal {months}{' '}
            months worth of payments.
          </p>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="relative w-full max-w-xl mx-auto mb-6 mt-4 h-[100px]">
        <StepsCircles steps={STEPS} />
        {isPreLoading ? (
          <Loading text="Finding your rebates..." />
        ) : (
          <>
            <div className="flex flex-col w-full">
              <br />
              {mainCopy}
              {middleCopy}

              <div className="bg-gray-100 border-2 border-green-500 p-2 rounded-md mb-4">
                {boxCopy}
              </div>

              <div className="flex-grow border-t border-gray-200"></div>

              <h1 className="text-black text-2xl text-left font-bold mb-2 mt-4">
                Start application to pay with an Upfront installment plan
              </h1>
              <p className="mt-2 text-left text-lg text-gray-600">
                <a href="#" className="font-medium text-gray-600">
                  We will store and send a verification code to this phone number:
                </a>
              </p>
              <Formik
                initialValues={{}}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  setIsPostLoading(true);
                  onContinuePhone(values, rebatesToday, instantRebatesToday);
                }}
              >
                {({ isSubmitting, handleSubmit }) => (
                  <Form className="mt-4 space-y-6">
                    <div className="flex items-center justify-center">
                      <div>
                        <Field
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text"
                          className="isolate inline-flex rounded-md shadow-sm h-12 text-base"
                        >
                          {({ field, form, meta }) => (
                            <div>
                              <PhoneInput
                                placeholder="Phone number"
                                country={'us'}
                                inputClass="focus:border-[#166534] focus:ring-[#166534] rounded-md"
                                onChange={(phone, country) => {
                                  form.setFieldValue(field.name, phone);
                                }}
                                onEnterKeyPress={handleSubmit}
                                inputProps={{
                                  id: 'phone',
                                }}
                              />
                            </div>
                          )}
                        </Field>

                        <div className="text-red-500">
                          <ErrorMessage name="phoneNumber" />
                        </div>
                      </div>
                    </div>

                    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
                      <div className="w-full mb-2">
                        <ContinueButton isLoading={isPostLoading} />
                        <PreviousButton isLoading={isPostLoading} onClick={onBack} />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhoneNumber;
